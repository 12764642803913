import { useEffect, useState } from "react";
import { BaseModal, SmallButtonYellow, Spinner } from 'component-library'

import { useTranslation } from "react-i18next";
// import { PasswordCheck } from "../../regex/PasswordCheck";
import { useCustomNavigate } from "@components//navigate/useCustomNavigate";
import { useSearchParams } from "react-router-dom";
import { get2FaQr, login, setNewPassword, submit2FaQr } from "@api/API";
import { useNotification } from "@context/Notification/NotificationContext";
import { EmailCheck } from "@regex/EmailCheck";
// import { Spinner } from "@../component-library/lib/components/basic/Spinner";
import { useUser } from "@context/UserContext";
import TwoFactorAuthInputs from "@components/2fa/TwoFactor";

type SetupTwoFactorModalProps = {
  openModal: boolean;
  toggleModal: () => void;
  showCloseButton?: boolean;
  darkMode?: boolean;
}

export const SetupTwoFactorModalConstructor = ({ openModal, toggleModal, darkMode = true }: SetupTwoFactorModalProps) => {
  const { t } = useTranslation();
  const { notify } = useNotification();
  const { user, isAuth } = useUser() as any;
  const initialInputValues = Array(6).fill('');
  const [inputValues, setInputValues] = useState<string[]>(initialInputValues);
  const customNavigate = useCustomNavigate();
  const [searchParams] = useSearchParams();
  const [qrCode, setQrCode] = useState<string | null>(null)
  const [loginDetails, setLoginDetails] = useState({
    email: ''
  })
  const [disabled, setDisabled] = useState(false)

  
  useEffect(() =>{
    if(user){
      updateUserDetails()
      handleRequestQr()
    }
  }, [user])

  const updateUserDetails = () => {
    isAuth && 
      setLoginDetails({
        email: user.email
      })
  }

  const handleRequestQr = async() => {
    if(!isAuth){
      notify(t('loginRequired'), "error")
      customNavigate('/inloggen')
    } 
    try{
      setDisabled(true)
      const response = await get2FaQr()
      if(response.status === 200){
        setQrCode(response.data.svg)
      } else {
        notify(t('requestTwoFactorError'), "error")
      }
      console.log('response', response)
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleSubmitQr = async() => {
    setDisabled(true)
    const response = await submit2FaQr(inputValues.join(''))
    if(response.status === 202){
      notify(t('twoFactorSuccess'), "success")
      customNavigate('/inloggen')
    } else {
      notify(t('twoFactorError'), "error")
    }
  }

  const checkDisabled = () => {
    return EmailCheck(loginDetails.email)  
  }
  const handleInputChange = (newInputValues: string[]) => {
    setInputValues(newInputValues);
  };

  return(
    <BaseModal
      open={openModal}
      toggleOpen={toggleModal}
      darkMode={darkMode}
      modalTitle={t('requestTwoFactor')}
      modalSubtitle={t('requestTwoFactorSubtitle')}
    >
      <div className='flex flex-col gap-y-4 mt-2 mb-8 flex-1'>
        <div className="p-4 aspect-square bg-slate-100 rounded-lg shadow">
          {qrCode ? 
            <div className="grid place-content-center w-full h-full items-center">
              <div className="w-fit m-auto" dangerouslySetInnerHTML={{__html: qrCode}}></div>
              <div className="text-center pt-8 text-gray-500">{t('scanQrCode')}</div>
              <div className="pt-8">
                <TwoFactorAuthInputs value={inputValues} onChange={handleInputChange}/>
              </div>
            </div>
            : 
            <div className="w-full h-full text-center grid place-content-center"><Spinner /></div>
          }
        </div>
      </div>
      <div className='w-full px-8 mt-8 '>
        <SmallButtonYellow
          text={!qrCode ? t('requestQrCode') : t('submitQrCode')}
          onClick={() => !qrCode ? handleRequestQr() : handleSubmitQr()}
          disabled={!checkDisabled()}
          darkMode={darkMode}
        />
      </div>
      <div className='w-full pt-4 px-8'>
      </div>
    </BaseModal>
  )
}