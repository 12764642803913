import { useEffect, useState } from "react";
import PointerImg from '@assets/PointerImg.png'
import CardImage from '@assets/CardImage.png'

import { BaseModal, SmallButtonYellow } from 'component-library'

// import { EmailCheck } from './../regex/EmailCheck'
import { useTranslation } from "react-i18next";
import { useCustomNavigate } from "@components/navigate/useCustomNavigate";
import { ChooseCJPSelector } from "./ChooseCJPSelector";
import { useUser } from "@context/UserContext";
import { useNotification } from "@context/Notification/NotificationContext";

type ChooseCJPModalProps = {
  openModal: boolean;
  toggleModal: () => void;
  showCloseButton?: boolean;
  darkMode?: boolean;
}

export const ChooseCJPModalConstructor = ({ openModal, toggleModal, darkMode = false }: ChooseCJPModalProps) => {
  const { t } = useTranslation() 
  const customNavigate = useCustomNavigate(); 
  const { isAuth, isLoading } = useUser() as any;
  const { notify } = useNotification();

  useEffect(() =>{
    if(!isLoading){
      if(!isAuth){
        customNavigate('/inloggen')
        notify(t('loginRequired'), "error")
      }
    }
  }, [isAuth, isLoading])

  return(
    <BaseModal
      open={openModal}
      toggleOpen={toggleModal}
      modalTitle={t('chooseCjp')}
      modalSubtitle={""}
      darkMode={darkMode}
    >
      <div className="">
        <ChooseCJPSelector darkMode={darkMode} />
      </div>
      <div className="text-center cursor-pointer underline mt-8" onClick={()=>customNavigate('/account')}>
        {t('continueWithout')}
      </div>
    </BaseModal>
  )
}