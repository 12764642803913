import { useEffect, useRef, useState } from 'react';
import { DayPicker } from './DaySelect';
import { MonthPicker } from './MonthSelect';
import { YearPicker } from './YearSelect';
import { InputField, SmallButtonYellow } from 'component-library';
import { t } from 'i18next';
import { CalendarDaysIcon } from '@heroicons/react/20/solid';
import { parseDate } from './DateUtils';


export const CustomDatePicker = ({date, updateDate, errorObj}: any) => {
  const [openDate, setOpenDate] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const initialDate = parseDate(date);

  const toggleOpen = () => {
    setOpenDate(!openDate);
  }

  const [selectedDay, setSelectedDay] = useState(initialDate.day);
  const [selectedMonth, setSelectedMonth] = useState(initialDate.month);
  const [selectedYear, setSelectedYear] = useState(initialDate.year);

  const monthNames = [
    'januari', 'februari', 'maart', 'april', 'mei', 'juni',
    'juli', 'augustus', 'september', 'oktober', 'november', 'december'
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        toggleOpen();
      }
    };
    if (openDate) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openDate, toggleOpen]);

  useEffect(() => {
    let date = {day: selectedDay, month: selectedMonth, year: selectedYear}
    date.month = String(monthNames.indexOf(date.month) + 1)
    updateDate(`${date.day}-${date.month}-${date.year}`)
  }, [selectedDay, selectedMonth, selectedYear])

  const darkMode = false;

  const selectedIsToday = () => {
    const [day, month, year] = date.split('-').map(Number);
    const selectedDate = new Date(year, month - 1, day);
    const today = new Date();
    return selectedDate.toDateString() === today.toDateString();
  }
  
  return (
    <div ref={modalRef}>
      <div className='relative' 
        onClick={() => setOpenDate(!openDate)} 
        onFocus={()=>setOpenDate(true)}
        onBlur={()=>setOpenDate(false)}  
      >
        <InputField
          placeholder={t("dateOfBirth")}
          value={`${selectedDay} ${selectedMonth} ${selectedYear}`}
          setState={() => {}}
          type="text"
          error={!selectedIsToday() && errorObj.date}
          handleUpdate={updateDate}
          itemKey={'dateBirth'}
          darkMode={darkMode}
          errorMsg={t("incorrectDateError")}
        />
        <div className='absolute right-0 top-6 cursor-pointer text-main-color' onClick={() => setOpenDate(!openDate)}>
          <CalendarDaysIcon className='h-6 w-6 ' />
        </div>
      </div>
      {/* <div className="mt-4 font-bold" onClick={() => setOpenDate(!openDate)}>
        Date picker
      </div> */}
      <div className="relative w-full">
        {openDate && (
          <div className="absolute z-50 w-full  bg-white shadow-xl rounded p-4">
            <div className="w-full h-[250px]">
              <div className='flex flex-row'>
                <div className="font-bold">SELECT A DATE</div>
                <div className='ml-auto w-fit text-sm text-main-color'>
                  {selectedDay} {selectedMonth} {selectedYear}
                </div>
              </div>
              <div className="flex flex-row">
                <DayPicker selectedDay={selectedDay} onSelectDay={setSelectedDay} />
                <MonthPicker selectedMonth={selectedMonth} onSelectMonth={setSelectedMonth} />
                <YearPicker selectedYear={selectedYear} onSelectYear={setSelectedYear} />
              </div>
            </div>
            <div className='w-full h-12 bg-white'> 
              <SmallButtonYellow
                text={"Bevestigen"}
                onClick={toggleOpen}
                disabled={false}
                darkMode={darkMode}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
