import { BaseModal, SmallButtonYellow } from 'component-library'
import CarouselComponent from "@components/carousel/Carousel";
import { useTranslation } from "react-i18next";
import { useCustomNavigate } from "@components/navigate/useCustomNavigate";
import { Header } from '@components/header-footer/Header';
import { Footer } from '@components/header-footer/Footer';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

type LoginModalProps = {
  openModal: boolean;
  toggleModal: () => void;
  showCloseButton?: boolean;
  darkMode?: boolean;
}

export const LoginModalConstructor = ({ openModal, toggleModal, showCloseButton = false, darkMode = true }: LoginModalProps) => {
  const { t } = useTranslation()
  const customNavigate = useCustomNavigate();   

  const [searchParams] = useSearchParams();
  const subCode = searchParams.get('sub') as string;

  useEffect(() => {
    if (subCode) {
      localStorage.setItem('subCode', subCode);
    } else {
      localStorage.removeItem('subCode');
    }
  }, [subCode]);

  return (
    <div className='bg-white'>
      <BaseModal
        open={openModal}
        toggleOpen={toggleModal}
        darkMode={darkMode}
        showLogo={false}
        // className="overflow-hidden" 
      > 
        <div className='mt-8 flex-1'>
          <CarouselComponent darkMode={darkMode}/>
        </div>
        <div className='w-full px-8 mt-8'>
          <SmallButtonYellow
            text={t('login')}
            onClick={() => customNavigate('/inloggen')}
            disabled={false}
            darkMode={darkMode}
          />
        </div>
        <div className='w-full px-8 mt-2 '> 
          <SmallButtonYellow
            text={t('createAccount')}
            onClick={() => customNavigate('/account-aanmaken')}
            disabled={false}
            darkMode={darkMode}
            inverted={true}
          />
        </div> 
      </BaseModal>
    </div>
  )
}
