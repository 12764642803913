import { useSearchParams, useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.svg';

export const Header = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();  

    const origin = searchParams.get('origin') || '';  
    const fullUrl = window.location.href; 

    const checkOriginFromUrl = (keyword: string) => {
        return fullUrl.includes(keyword);
    };

    const isActive = (targetOrigin: string) => {
        if (origin === 'scholen' || origin === 'partners') {
            return origin === targetOrigin;
        } else if (targetOrigin === 'mijn') {
            return checkOriginFromUrl('mijn');
        } else {
            return checkOriginFromUrl(targetOrigin);
        }
    };

    const handleButtonClick = (targetOrigin: string) => {
        navigate(`/inloggen?origin=${targetOrigin}`);
    };
    
    return (
        <header className="bg-white p-4 border-b border-gray-200 fixed top-0 left-0 right-0">
            <div className='flex justify-between items-center'>
                <a href="https://www.cjp.nl/" target="_blank" rel="noreferrer">
                    <img src={logo} alt='logo' className='ml-8'/>
                </a>
                {(fullUrl.includes('aanmelden') || fullUrl.includes('inloggen')) &&
                    <div className='flex space-x-4 mr-12 text-sm font-bold'>
                        <button
                            onClick={() => handleButtonClick('mijn')}  
                            className={`px-4 py-2 border rounded-full transition-colors 
                            ${isActive('mijn') ? 'border-main-color text-main-color' : 'border-transparent text-gray-700 hover:border-main-color hover:text-main-color'}`}>
                            Mijn CJP
                        </button>
                        <button
                            onClick={() => handleButtonClick('scholen')}  
                            className={`px-4 py-2 border rounded-full transition-colors 
                            ${isActive('scholen') ? 'border-main-color text-main-color' : 'border-transparent text-gray-700 hover:border-main-color hover:text-main-color'}`}>
                            Scholen
                        </button>
                        <button
                            onClick={() => handleButtonClick('partners')}  
                            className={`px-4 py-2 border rounded-full transition-colors 
                            ${isActive('partners') ? 'border-main-color text-main-color' : 'border-transparent text-gray-700 hover:border-main-color hover:text-main-color'}`}>
                            Partners
                        </button>
                    </div>
            }
            </div>
        </header>
    );
};
