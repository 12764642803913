import { XMarkIcon } from '@heroicons/react/16/solid';
import { InformationCircleIcon } from '@heroicons/react/16/solid';
import { ExclamationCircleIcon } from '@heroicons/react/16/solid';
import { CheckIcon } from '@heroicons/react/16/solid';
import React, { useEffect, useState } from 'react';

interface NotificationProps {
  content: string;
  type: 'success' | 'error' | 'warning' | 'info';
  index: number;
}

const notificationColor = {
  success: 'text-green-500 border-green-500',
  error: 'text-red-500 border-red-500',
  warning: 'text-yellow-500 border-yellow-500',
  info: 'text-blue-500 border-blue-500',
}; 

const typeIcon = (type: string) => {
  switch(type) {
    case 'success':
      return <CheckIcon className='w-6 h-6' />
    case 'error':
      return <XMarkIcon className='w-6 h-6' />
    case 'warning':
      return <ExclamationCircleIcon className='w-6 h-6' />
    case 'info':
      return <InformationCircleIcon className='w-6 h-6' />
    default:
      return <CheckIcon className='w-6 h-6' />
  }
}

const notificationPosition = {
  0: 'top-[10px]',
  1: 'top-[80px]',
  2: 'top-[150px]',
  3: 'top-[220px]',
  4: 'top-[290px]',
  5: 'top-[360px]',
  6: 'top-[430px]',
} as any;

const Notification: React.FC<NotificationProps> = ({ content, type, index }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const entryAnimationTimeout = setTimeout(() => {
      setIsVisible(true); 
    }, 100);

    const exitAnimationTimeout = setTimeout(() => {
      setIsVisible(false);
    }, 9000);

    return () => {
      clearTimeout(entryAnimationTimeout);
      clearTimeout(exitAnimationTimeout);
    };
  }, []);

  return (
    <div className={`w-fit h-fit fixed right-4 ${notificationPosition[index]} z-[9999]`}>
      <div className={`${isVisible ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-[120%]'} ease-in-out transition-all duration-300 min-w-[350px] h-fit items-center py-4 pr-2 border-l-4 shadow-lg bg-white flex ${notificationColor[type]}`}>
        <div className='px-1'>{typeIcon(type)}</div>
        <div className='flex-1'>{content}</div>
      </div>
    </div>
  );
};


export default Notification; 
