import { useEffect, useState } from "react";

import { BaseModal, InputField, SmallButtonYellow, Checkbox } from 'component-library'

import { EmailCheck } from '@regex/EmailCheck'
import { PasswordCheck } from '@regex/PasswordCheck'
import { useTranslation } from "react-i18next";
import { checkCompromisedPassword, createNewAccount } from "../api/API";
import { useUser } from "@context/UserContext";
import { useCustomNavigate } from "@components/navigate/useCustomNavigate";
import { PostalCheck } from "@regex/PostalCheck";
import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useNotification } from "@context/Notification/NotificationContext";
import { InformationCircleIcon } from "@heroicons/react/16/solid";
import { CustomDatePicker } from "@components/CustomDatePicker";

type CreateAccountModalProps = {
  openModal: boolean;
  toggleModal: () => void;
  showCloseButton?: boolean;
  darkMode?: boolean;
}

export const CreateAccountModalConstructor = ({ openModal, toggleModal, darkMode = true }: CreateAccountModalProps) => {
  const { t } = useTranslation()
  const { notify } = useNotification();
  const [isLoading, setIsLoading] = useState(false)
  const customNavigate = useCustomNavigate();
  // const [dateError, setDateError] = useState(false)
  const [emailInUseError, setEmailInUseError] = useState(false)
  // const [isError, setIsError] = useState(false)
  const [modalTitle, setModalTitle] = useState(t('createAccount'));
  const [emailSent, setEmailSent] = useState(false);  

  useEffect(() => {
    if (emailSent) {
      setModalTitle(t('Bevestig je e-mailadres'));
    } else {
      setModalTitle(t('createAccount'));
    }
  }, [emailSent]);

  const [errorObj, setErrorObj] = useState({
    date: false
  })
  const [showTooltip, setShowTooltip] = useState(false); 
  // const [errorObj2, setErrorObj2] = useState({
  //   date: false
  // })
  // const [confirmText, setConfirmText] = useState('') 
  // const [removeSub, setRemoveSub] = useState(false)

  const checkUserAge = () => {
    /** Uses loginDetails.dateBirth to calculate the user's age accurately based on today's date */
    const [day, month, year] = loginDetails.dateOfBirth.split('-').map(Number);
    const selectedDate = new Date(year, month - 1, day); // month is zero-indexed
    const today = new Date();
  
    let age = today.getFullYear() - selectedDate.getFullYear();
  
    // Adjust age if the user's birthday has not occurred yet this year
    const hasHadBirthdayThisYear =
      today.getMonth() > selectedDate.getMonth() ||
      (today.getMonth() === selectedDate.getMonth() && today.getDate() >= selectedDate.getDate());
  
    if (!hasHadBirthdayThisYear) {
      age -= 1;
    }
    return age;
  };


  const checkDateOfBirth = () => {
    const [day, month, year] = loginDetails.dateOfBirth.split('-').map(Number);
    const selectedDate = new Date(year, month - 1, day);
    if(
      (selectedDate > new Date()) ||
      (selectedDate.getFullYear() < 1925) ||
      (checkUserAge() < 10)
    ) {
      setErrorObj({
        ...errorObj,
        date: true
      })
    }
    else {
      setErrorObj({
        ...errorObj,
        date: false
      })
    }
  }

  const getToday = () => {
    let date = new Date();
    let day = date.getDate().toString().padStart(2, '0');
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const [loginDetails, setLoginDetails] = useState({
    email: '',
    password: '',
    // confirmEmail: '',
    dateOfBirth: getToday(),
    isSubscriber: false,
    postal: '',
  })
  // const { fetchUserDetails } = useUser(); 
  const [validations, setValidations] = useState({
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumber: false,
    isLongEnough: false,
    isNotComporimised: false
  });

  const validatePassword = async(password: string) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const isLongEnough = password.length >= 8;
    const isNotComporimised = await checkPassword()

    setValidations({
      hasUpperCase,
      hasLowerCase,
      hasNumber,
      isLongEnough,
      isNotComporimised
    });
  };

  
  useEffect(() => {
    checkDateOfBirth()
  }, [loginDetails.dateOfBirth])

 
  const checkPassword = async() => {
    if(loginDetails.password.length < 7) return false
    let res = await checkCompromisedPassword(loginDetails.password)
    if(res.status === 200) return true
    else return false
    // return false
  }

  useEffect(() => {
    validatePassword(loginDetails.password);
  }, [loginDetails.password])
 

  const updateLoginDetails = (value: any, state: string) => {
    setLoginDetails({
      ...loginDetails,
      [state]: value
    })
  } 

  const checkDisabled = () => {
    if(!validations.isNotComporimised) return true
    if (
      loginDetails.email.length == 0 || 
      loginDetails.password.length == 0 || 
      // loginDetails.confirmEmail.length == 0 ||
      // loginDetails.confirmEmail !== loginDetails.email ||
      !EmailCheck(loginDetails.email) || 
      !PasswordCheck(loginDetails.password) || 
      errorObj.date || 
      loginDetails.postal.length == 0 || 
      !PostalCheck(loginDetails.postal)
    ) {
      return true
    } else {
      return false
    }
  } 

  // const formatDate = (dateStr: string) => {
  //   return dateStr.split('-').reverse().join('-');
  // };


  const handleCreateAccount = async () => { 
    setIsLoading(true)
    const data = {
      postalCode: loginDetails.postal,
      phone: '',
      parentEmail: '',
      deviceId: 'DEVICE123456',
      firstName: '',
      lastName: '',
      email: loginDetails.email,
      password: loginDetails.password,
      dateOfBirth: loginDetails.dateOfBirth,
      isSubscriber: String(loginDetails.isSubscriber)
    } 

    const response = await createNewAccount(data)
    if(response?.status === 200){ 
      //wait 50ms for DB to be finished
      // console.Console.l
      // if(response.data.message.includes("Pending")){
      //   setEmailSent(true)
      // } else {
      //   setTimeout(() => {
      //     handleLogin()
      //   }, 50) 
      // }
      setEmailSent(true)
    } else {
      if(response.data.message === "User already exists."){
        setEmailInUseError(true)
        setIsLoading(false)
      } else if (response.data.message.toLowerCase().includes('pending')) {
        notify("Er is al en account aangevraagd voor dit e-mailadres. Open je e-mail en bevestig je e-mailadres.", 'error')
        setIsLoading(false)
      } else {
        notify(response.data.message, 'error')
        setIsLoading(false)
        console.log('error', response)
      }
    }
  }

  // const handleLogin = async () => {
  //   setIsLoading(true)  
  //   const response = await login(loginDetails.email, loginDetails.password, '')
  //   if(response.status === 429) notify('Te veel inlogpogingen, probeer het later opnieuw', 'error')
  //   if(response.status === 200){ 
  //     //wait 50ms for DB to be finished
  //     setTimeout(() => {
  //       setUserDetails() 
  //     }, 50)
  //   } else {
  //     console.log('error', response)
  //   }
  //   setIsLoading(false) 
  // }

  const updateDate = (value: any, state: string) => { 
    setLoginDetails({
      ...loginDetails,
      ['dateOfBirth']: value
    })
  }

  return(
    <BaseModal
      open={openModal}
      toggleOpen={toggleModal}
      darkMode={darkMode}
      modalTitle={modalTitle}
      // modalSubtitle={!emailSent ? t('createAccountSubtitle') : ""}
    > 
     {!emailSent ? <>
        <div className='flex flex-col gap-y-1 flex-1'>
          <div className="relative">
            <InputField
              placeholder={t("privateEmail")}
              value={loginDetails.email}
              setState={() => { }}
              type="text"
              error={loginDetails.email.length > 0 && !EmailCheck(loginDetails.email)}
              handleUpdate={updateLoginDetails}
              itemKey={'email'}
              darkMode={darkMode}
              errorMsg={t("emailError")}
            />
            <InformationCircleIcon
              className="w-6 h-6 right-0 top-4 text-gray-300 cursor-pointer absolute"
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              onClick={() => setShowTooltip(!showTooltip)}
            />
            {showTooltip && (
              <div className="absolute right-0 mt-1 gap-y-1 w-52 p-2 bg-[#FAFCC6] rounded-lg shadow-lg text-black text-sm z-50">
                <p className="font-bold">Een privé e-mailadres?</p>
                <p className="mt-2">Bijvoorbeeld jouw Gmail, Outlook of iCloud e-mailadres.</p>
                <p className="mt-2">Gebruik een e-mailadres waarop je goed te bereiken bent zodat je account nooit verloren gaat.</p>
              </div>
            )}
          </div>
        {emailInUseError &&
          <div> 
            <div className="text-red-500 text-sm mt-1 flex items-center center-items gap-x-2">
              <InformationCircleIcon className="h-6 w-6 mr-1" />
              <div dangerouslySetInnerHTML={{__html: t('emailInUseError')}}>
              </div>
            </div>
          </div>
        }
        {/* <InputField
          placeholder={t("confirmEmail")}
          value={loginDetails.confirmEmail}
          setState={() => {}}
          type="text"
          error={loginDetails.confirmEmail.length > 0 && loginDetails.confirmEmail !== loginDetails.email}
          handleUpdate={updateLoginDetails}
          itemKey={'confirmEmail'}
          darkMode={darkMode}
          errorMsg={t("confirmEmailError")}
        /> */}
        <InputField
          placeholder={t("password")}
          value={loginDetails.password}
          setState={() => {}}
          type="password"
          error={loginDetails.password.length > 0 && !PasswordCheck(loginDetails.password)}
          handleUpdate={updateLoginDetails}
          itemKey={'password'}
          darkMode={darkMode}
          errorMsg={' '}
        />
        {(loginDetails.password.length > 0 && (!PasswordCheck(loginDetails.password) || !validations.isNotComporimised)) &&
          <div className="text-sm">
            <div className="flex flex-row gap-x-2">
              <div>
              {validations.hasUpperCase ?
                <CheckIcon className={`w-5 h-5 text-green-500`} />
                :
                <XMarkIcon className={`w-5 h-5 text-red-500`} />
              }
              </div>
              <div>
                {t("passwordUpperCase")}
              </div>
            </div>
            <div className="flex flex-row gap-x-2">
              <div>
              {validations.hasLowerCase ?
                <CheckIcon className={`w-5 h-5 text-green-500`} />
                :
                <XMarkIcon className={`w-5 h-5 text-red-500`} />
              }
              </div>
              <div>
                {t("passwordLowerCase")}
              </div>
            </div>
            <div className="flex flex-row gap-x-2">
              <div>
              {validations.hasNumber ?
                <CheckIcon className={`w-5 h-5 text-green-500`} />
                :
                <XMarkIcon className={`w-5 h-5 text-red-500`} />
              }
              </div>
              <div>
                {t("passwordNumber")}
              </div>
            </div>
            <div className="flex flex-row gap-x-2">
              <div>
              {validations.isLongEnough ?
                <CheckIcon className={`w-5 h-5 text-green-500`} />
                :
                <XMarkIcon className={`w-5 h-5 text-red-500`} />
              }
              </div>
              <div>
                {t("passwordIsLongEnough")}
              </div>
            </div>
            {loginDetails.password.length > 7 &&
              <div className="flex flex-row gap-x-2">
                <div>
                {validations.isNotComporimised ?
                  <CheckIcon className={`w-5 h-5 text-green-500`} />
                  :
                  <XMarkIcon className={`w-5 h-5 text-red-500`} />
                }
                </div>
                <div>
                  {"Wachtwoord is gevonden in database met gelekte wachtwoorden."}
                </div>
              </div>
            }
          </div>
        }
        <InputField
          placeholder={t('postal')}
          value={loginDetails.postal}
          setState={() => {}}
          type="text"
          error={loginDetails?.postal.length > 0 && !PostalCheck(loginDetails.postal)}
          handleUpdate={updateLoginDetails}
          itemKey={'postal'}
          darkMode={darkMode}
          errorMsg={t('postalError')}
        />
        {/* <DateSelect
          placeholder={t("dateOfBirth")}
          value={loginDetails.dateOfBirth}
          setState={() => {}}
          type="password"
          error={false}
          handleUpdate={updateLoginDetails}
          itemKey={'dateOfBirth'}
          darkMode={darkMode}
          errorMsg={t("dateOfBirthError")}
        /> */}

        <CustomDatePicker date={loginDetails.dateOfBirth} updateDate={updateDate} errorObj={errorObj} />

        {/* <Slider
          value={loginDetails.isSubscriber}
          onClick={() => updateLoginDetails(!loginDetails.isSubscriber, 'isSubscriber')}
          darkMode={darkMode}
          text={t("discountSubscription")}
        /> */}
        <div className="pt-2">

        </div>
        <div className="bg-[#FAFCC6] rounded-lg shadow-md p-2 text-sm">
          <label className="flex items-center cursor-pointer">
            <Checkbox
              value={loginDetails.isSubscriber}
              onClick={() => updateLoginDetails(!loginDetails.isSubscriber, 'isSubscriber')}
              darkMode={darkMode}
              text={t('discountSubscription')}
            />
          </label>
        </div>
        <div className="pt-2 cursor-default text-xs" dangerouslySetInnerHTML={{__html: t('agreeTermsConditions')}}>
          {/* {t('agreeTermsConditions')} */}
        </div>
      </div>
      <div className='w-full px-8 mt-8 '>
        <SmallButtonYellow
          text={t("joinNow")}
          onClick={() => handleCreateAccount()}
          disabled={checkDisabled()}
          darkMode={darkMode}
          loading={isLoading}
        />
      </div>
      </>
      :
      // <div className="cursor-default">
      //   We hebben een e-mail gestuurd naar <span className="font-bold">{loginDetails.email}</span>. <br /> Klik op de link om je e-mailadres te bevestigen en je account aan te maken. <br /> Als je de e-mail niet hebt ontvangen, controleer dan je spam map.
      // </div>
     <div className="cursor-default">
    <ul className="list-disc pl-5">
        <p className="mb-2">We hebben een e-mail gestuurd naar <span className="font-bold">{loginDetails.email}</span>.</p>
        <li><p className="mb-2">Open je e-mail en klik op de link om je account aan te maken.</p></li>
        <li><p>Als je geen e-mail hebt ontvangen, controleer dan je spam map.</p></li>
    </ul>
</div>
      }
      <div className='w-full pt-4 px-8'>
        <div className={`text-center cursor-pointer underline ${darkMode ? "text-white" : "text-black" }`} onClick={()=>customNavigate('/inloggen')}>
          {t("back")}
        </div>        
      </div>
    </BaseModal>
  )
} 