import { useState, useEffect } from 'react';
import logo from '../../assets/logo.svg';

export const Footer = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkScreenSize = () => {
            const isSmallScreen = window.innerWidth < 768; 
            setIsMobile(isSmallScreen);
        };

        checkScreenSize();

        window.addEventListener('resize', checkScreenSize);

        return () => window.removeEventListener('resize', checkScreenSize);
    }, []);

    return (
        <footer className={`bg-white p-4 border-t border-gray-200 w-full ${isMobile ? 'relative' : 'absolute bottom-0 pl-16'}`}>
            <div className={`flex ${isMobile ? 'flex-col items-center' : 'justify-between items-center'} flex-wrap`}>
                <div className={`flex items-center ${isMobile ? 'mb-4' : ''}`}>
                    {/* <img src={logo} alt='logo' /> */}
                    <button
                        className=' bg-black text-white rounded-full px-6 py-1'
                        onClick={() => window.open('https://support.cjp.nl', '_blank')}
                    >
                        Support
                    </button>
                </div>

                <div className={`text-sm flex ${isMobile ? 'flex-col items-center gap-4' : 'gap-6 pr-16'} `}>
                    <a href="https://www.cjp.nl/privacy" target="_blank" rel="noopener noreferrer">Privacybeleid</a>
                    <a href="https://www.cjp.nl/algemene-voorwaarden" target="_blank" rel="noopener noreferrer">Algemene voorwaarden</a>
                    <a href="https://www.cjp.nl/cultuurkaart-avg" target="_blank" rel="noopener noreferrer">Cultuurkaart & AVG</a>
                    <a href="https://www.cjp.nl/cookiebeleid" target="_blank" rel="noopener noreferrer">Cookiebeleid</a>
                    <a href="https://www.cjp.nl/disclaimer" target="_blank" rel="noopener noreferrer">Disclaimer</a>
                </div>
            </div>
        </footer>
    );
};
