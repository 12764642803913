import { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';   
import { ActivateAccountModalConstructor } from '@components/modal/ActivateAccountModal';
import { ChooseCJPModalConstructor } from '@components/modal/ChooseCJPModal';
import { RequestVOModalConstructor } from '@components/modal/RequestVOModalConstructor';
import { RequestMBOModalConstructor } from '@components/modal/RequestMBOModalConstructor';

import "/node_modules/flag-icons/css/flag-icons.min.css";
import { useNotification } from '@context/Notification/NotificationContext';
import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '@components/navigate/useCustomNavigate';
import { SuccessModalConstructor } from '@components/modal/SuccessModalConstructor';
import { BuySubscriptionModalConstructor } from '@components/modal/BuySubscriptionModal';

import { Header } from '@components/header-footer/Header';
import { Footer } from '@components/header-footer/Footer';

function SubscriptionModal() {
  const { t } = useTranslation();
  const customNavigate = useCustomNavigate();
  const [openModal, setOpenModal] = useState(true);
  const toggleModal = () => setOpenModal(true);
  const { notify } = useNotification();
  const [searchParams] = useSearchParams();
  const { modal } = useParams() || "";
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 768); 
    };

    checkScreenSize();

    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  let errorMsgIndex = 0

  useEffect(() => { 
    const error = searchParams.get('error');
    if(error && errorMsgIndex === 0){
      errorMsgIndex++
      notify(t('error-'+error, { portal: origin }), 'error');
      customNavigate('/inloggen')
    }
  }, []); 

  const darkMode = false;
  
  const renderModal = () => {
    switch (modal) {
      case 'vo':
        return <RequestVOModalConstructor openModal={openModal} toggleModal={toggleModal} darkMode={darkMode}/>;
      case 'mbo':
        return <RequestMBOModalConstructor openModal={openModal} toggleModal={toggleModal} darkMode={darkMode}/>;
      case 'activeren':
        return <ActivateAccountModalConstructor openModal={openModal} toggleModal={toggleModal} darkMode={darkMode}/>;
      case 'success':
        return <SuccessModalConstructor openModal={openModal} toggleModal={toggleModal} darkMode={darkMode}/>;
      case 'kopen':
        return <BuySubscriptionModalConstructor openModal={openModal} toggleModal={toggleModal} darkMode={darkMode}/>;
      default:
        return <ActivateAccountModalConstructor openModal={openModal} toggleModal={toggleModal} darkMode={darkMode}/>;
    }
  };

  return (
    <div className={`bg-slate-100 flex flex-col ${isMobile ? 'min-h-[130vh]' : 'min-h-screen'} ${darkMode ? "dark" : ""}`}>
      <header className="fixed top-0 left-0 right-0 z-[9999]">
        <Header />
      </header>

      {/* <div className='absolute inset-0 bg-cover bg-center z-0' style={{ backgroundImage: 'url(https://cdn.britannica.com/51/194651-050-747F0C18/Interior-National-Gallery-of-Art-Washington-DC.jpg)' }}></div> */}
      <div className="flex-grow flex flex-col items-center justify-center">
      {modal ?
        <div className={` w-screen h-screen absolute inset-0 grid place-content-center z-50 ${openModal ? '' : 'pointer-events-none'}`} >
          {renderModal()}
        </div>
      :
      <div className={` w-screen h-screen absolute inset-0 grid place-content-center z-50 ${openModal ? '' : 'pointer-events-none'}`} >
        <ChooseCJPModalConstructor openModal={openModal} toggleModal={toggleModal} darkMode={darkMode}/>
      </div>
      }
      </div>

      <footer className={`w-full ${isMobile ? ' mt-4' : 'fixed bottom-0 left-0 right-0'} z-50`}>
        <Footer />
      </footer>
    </div>
  );
}

export default SubscriptionModal;
