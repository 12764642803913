import { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { getProfile, logout } from '../components/api/API';

// Define interfaces that match the structure of your API response
// interface UserProfile {
//   uuid: string;
//   first_name: string;
//   last_name: string;
//   gender: string;
//   phone: string;
//   status: string;
//   marketing_emails_optin: boolean;
//   is_accepted: boolean;
//   avatar: string;
//   position: string;
//   date_birth: string;
//   is_subscriber: boolean;
//   individual_profile: any;
//   teacher_plus: boolean;
//   teacher_details: any;
//   student_details: any;
//   budgetholder_details: any;
// }

// interface User {
//   uuid: string;
//   profile: UserProfile;
//   role: string;
//   email: string;
//   member: string;
//   is_first_login: boolean;
//   card: any;
//   card_full: any;
//   individual: any;
// }

interface Address {
  street: string;
  zip: string;
  housenr: string;
  housenrExt: string;
  province: string;
  city: string;
  country: string;
}

interface OptInPreferences {
  emailMarketing: boolean;
  pushNotifications: boolean;
}

interface UserData {
  email: string;
  firstName: string;
  lastName: string;
  gender: string;
  phone: string;
  dateJoined: string; // ISO 8601 format date string
  lastLogin: string | null;
  role: string;
  dateBirth: string; // format "dd-MM-yyyy"
  address: Address;
  optInPreferences: OptInPreferences;
  teacherPlus?: boolean;
}


interface UserContextType {
  user: UserData | null;
  setUser: (user: UserData) => void;
  isAuth: boolean;
  isLoading: boolean;
  fetchUserDetails: () => void;
  handleLogout: () => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<UserData | null>(null)
  const [isAuth, setIsAuth] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {  
    fetchUserDetails() 
  }, [])
 
  const fetchUserDetails = async () => { 
    setLoading(true)
    try {  
      const response = await getProfile(); 
      const data = response.data.data
      if(response.status === 200){
        setIsAuth(true)
        setUser({...data});  
      }
    } catch (error) {
      console.log('Failed to fetch user details'); 
    } finally {
      //add 150ms delay to make sure the states are all set 
      setTimeout(() => {
        setLoading(false)
      }, 150)
    }
  }; 

  const handleLogout = async () => {
    // Add your logout logic here
    let res = await logout()
    if(res.status === 200){
      setIsAuth(false)
      // setLoading(true)
    } 
  }

  return (
    <UserContext.Provider value={{ user, setUser, isAuth, isLoading, fetchUserDetails, handleLogout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  const returnObject = {
    user: context?.user,
    setUser: context?.setUser,
    isAuth: context?.isAuth,
    isLoading: context?.isLoading,
    fetchUserDetails: context?.fetchUserDetails,
    handleLogout: context?.handleLogout
  }
  return returnObject; 
};
