import { useState } from "react";
import PointerImg from '@assets/PointerImg.png'
import CardImage from '@assets/CardImage.png'

import { BaseModal, SmallButtonYellow } from 'component-library'

import { useTranslation } from "react-i18next";
import { useCustomNavigate } from "@components/navigate/useCustomNavigate";

type ChooseCJPModalProps = {
  openModal: boolean;
  toggleModal: () => void;
  showCloseButton?: boolean;
  darkMode?: boolean;
}

export const RequestVOModalConstructor = ({ openModal, toggleModal, showCloseButton = false, darkMode = false }: ChooseCJPModalProps) => {
  const { t } = useTranslation()
  const customNavigate = useCustomNavigate();
  const [showContent, setShowContent] = useState(true)

  return (
    <BaseModal
      open={openModal}
      toggleOpen={toggleModal}
      darkMode={darkMode}
      modalTitle={t('askTeacher')}
      modalSubtitle={t('activateCjpVOSubtitle')}
    > 
      <div className="gap-y-5 flex flex-col flex-1 mt-8">
        <div className="flex relative h-52 select-none">
          <div className={`w-fit h-[240px] ${darkMode ? "bg-white hover:bg-white/90" : "bg-slate-100 hover:bg-slate-200"} rounded-lg p-4 flex absolute animate`} onClick={()=>setShowContent(!showContent)}>
            <div className="uppercase bg-main-yellow aspect-square absolute -top-4 left-2 p-2 text-black/80 font-bold font-formula tracking-wider flex items-center text-xl">
              {t('free')}
            </div>
            <div>
              <img src={CardImage} alt="gratisImg" className="w-30 aspect-[3/5]" />
            </div>
            <div className="pl-8 pt-1 text-black/80 w-[calc(100%-9rem)]"> 
              <div className="uppercase font-bold font-formula text-2xl tracking-wide">
                {t('forStudentsAndTeachers')}
              </div>
              {/* <div className="uppercase font-bold font-formula tracking-wide text-4xl">
                {t('free')}
              </div> */}
              <div className="flex gap-x-2">
                <img 
                  src={PointerImg}
                  className="w-4 h-4 mt-1"
                />
                {t('freeForVOPerk')}
              </div>
              <div className="flex gap-x-2">
                <img 
                  src={PointerImg}
                  className="w-4 h-4 mt-1"
                />
                {t('continueWithoutCjpMembership')}
              </div>
            </div>
          </div> 
        </div>
      </div>
      <div className="w-full flex flex-row px-14">
        <div className={`underline cursor-pointer text-center  ${darkMode ? "text-white" : "text-black"}`} onClick={()=>customNavigate('/lidmaatschap')}>
          {t('back')}
        </div>
        <div className="text-center cursor-pointer underline ml-auto" onClick={()=>customNavigate('/account')}>
          {t('continueWithout')}
        </div>
      </div>
    </BaseModal>
  )
}
