import { SmallButtonYellow, Spinner } from "component-library";
import CardImage from '@assets/individual-2024.png'
import { ChooseCJPSelector } from "@components/modal/ChooseCJPSelector";
import { useCustomNavigate } from "@components/navigate/useCustomNavigate"; 
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";

type AccountOverviewProps = {
  darkMode: boolean;
  loadingObj: any;
  userProfile: any;
  activeSub: boolean;
  subscription: any;
}

export const AccountOverview = ({ darkMode, loadingObj, userProfile, activeSub, subscription }: AccountOverviewProps) => {
  const customNavigate = useCustomNavigate();

  const copyToClipboard = async (text: string) => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(text);
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        textArea.style.position = 'fixed'; // Avoid scrolling to bottom
        textArea.style.left = '-9999px'; // Hide the textarea off-screen
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        
        const successful = document.execCommand('copy');
        document.body.removeChild(textArea);
        
        if (!successful) {
          throw new Error('Failed to copy text');
        }
      }
      return true;
    } catch (error) {
      console.error('Failed to copy text to clipboard:', error);
      return false;
    }
  };
  
  const formatDate = (date: string) => {
    const [year, month, day] = date.split('T')[0].split('-');
    return `${day}-${month}-${year}`; 
  }

  const getImage = () => {
    if(subscription.cardFrontImageUrl.includes('docker')){
      return "http://mijn.dev-docker.com/cards/student-mbo-2024.png"
    }
    else return subscription.cardFrontImageUrl
  }

  return(
    <div className="mt-4">
      <div className={`font-bold ${darkMode ? "text-white" : "text-black"} text-left text-4xl font-formula tracking-wider pt-8`}>
        MIJN CJP
      </div>
      <hr className="my-4"/>
      {loadingObj.profile ?
        <div className="w-fit m-auto pt-12">
          <Spinner />
        </div>
      :
        <div className={`${darkMode ? "text-white" : "text-black"} `}>
          <div className="font-bold text-lg mt-2">
            {userProfile.firstName || ""} {userProfile.lastName || ""}
          </div>
          <div>
            {userProfile.email || ""}
          </div> 
          <div>
            {userProfile.phone || ""}
          </div> 
          <div>
            {userProfile.dateBirth || ""}
          </div>  
          {activeSub &&
            <div className="flex flex-row hover:border-main-color border-white animate group w-fit cursor-pointer border-b" onClick={()=>copyToClipboard(subscription.code)}>
              {subscription.code} <span><ClipboardDocumentIcon className="w-5 h-5 ml-2 animate text-gray-300 group-hover:text-main-color" /></span>
            </div>
          }
        </div>
      }
      <div className="pt-3 pb-4">
        <hr />
      </div> 
      {loadingObj.subscription ?
        <div className="w-fit m-auto pt-12">
          <Spinner />
        </div>
      :
      activeSub ?
        <div>
          {/* <div className={`font-bold ${darkMode ? "text-white" : "text-black"} text-left text-3xl font-formula tracking-wider`}>
            JOUW LIDMAATSCHAP:
          </div> */}
          {subscription.budgetAvailable > 0 &&
            <div className="pt-4 flex items-center">
              <div>
                Huidig tegoed: <span className="font-bold text-2xl">€{subscription.budgetAvailable}</span>
              </div>
              <div className="w-fit ml-auto">
                <SmallButtonYellow
                  text={"Naar het aanbod"}
                  onClick={() => window.location.href = "https://cjp.nl"}
                  disabled={false}
                  darkMode={darkMode}
                />
              </div>
            </div>
          }
          <div className="pt-4 relative select-noe">
            <div className="rounded-lg border-2 border-white overflow-hidden h-fit w-full">
              <img src={getImage()} alt="gratisImg" className="w-full aspect-[3/5]" />
            </div>
            <div className="absolute w-full text-center top-[35%] text-4xl text-white font-bold">
              <div className="text-6xl">
                {userProfile.firstName || ""} 
              </div>
              <div>
                {userProfile.lastName || ""}
              </div>
            </div>
            <div className="absolute w-full px-8 text-lg bottom-[15%] text-white">
              <div>
                <span className="font-bold">{subscription?.school?.name}</span>
              </div>
              <div>
                Geboortedatum: <span className="font-bold">{userProfile.dateBirth}</span>
              </div>
              <div>
                CJP nr: <span className="font-bold">{subscription.code}</span>
              </div>
              <div>
                Geldig tot: <span className="font-bold">{formatDate(subscription.endDate)}</span>
              </div>
            </div>
          </div>
          <div className="pt-4">
            <SmallButtonYellow
              text={"Nieuwe code activeren"}
              onClick={() => customNavigate('/lidmaatschap/activeren')}
              disabled={false}
              darkMode={darkMode}
            />
          </div>
        </div>
        :  
        <div>
          {/* <div className={`font-bold ${darkMode ? "text-white" : "text-black"} text-left text-3xl font-formula tracking-wider`}>
            ACTIVEER JE LIDMAATSCHAP:
          </div>  */}
          <div className="pb-4">
            {/* <div>
              Heb je al een CJP code? Activeer je lidmaatschap hier.
            </div> */}
            <div className="pt-4">
              <SmallButtonYellow
                text={"Activeer nu je lidmaatschap"}
                onClick={() => customNavigate('/lidmaatschap/activeren')}
                disabled={false}
                darkMode={darkMode}
              />
            </div>
          </div> 
          {/* <hr /> */}
          <div className="pt-4">
            <div>
              Heb je geen activatiecode en ben je jonger dan 30 of student of docent, kies dan hieronder wat op jou van toepassing is om een code te krijgen.
            </div>
            <div className="pt-8">
              <ChooseCJPSelector darkMode={darkMode} />
            </div>
          </div> 
        </div>
      }
      {/* <div className="pt-8">
        <SmallButtonYellow
          text={"Uitloggen"}
          onClick={() => handleLogOut()}
          disabled={false}
          darkMode={darkMode}
        />
      </div> */}
    </div>
  )
}