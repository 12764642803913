import { InformationCircleIcon } from '@heroicons/react/16/solid';
import React, { useRef, useEffect } from 'react';

interface TwoFactorAuthInputsProps {
  value: string[];
  onChange: (newValue: string[]) => void;
  error?: boolean;
}

const TwoFactorAuthInputs: React.FC<TwoFactorAuthInputsProps> = ({ value, onChange, error }) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const newInputValues = [...value];
    newInputValues[index] = event.target.value.slice(-1); // Take the last character to handle paste correctly
    onChange(newInputValues);

    if (index < inputRefs.current.length - 1 && event.target.value) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && value[index] === '' && index > 0) {
      const newInputValues = [...value];
      newInputValues[index - 1] = ''; // Clear the previous input
      onChange(newInputValues);
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handlePaste = (index: number, event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault(); // Prevent the default paste behavior
    const pasteContent = event.clipboardData.getData('text').slice(0, 6); // Limit to 6 characters
    
    if (/^[0-9]+$/.test(pasteContent)) {
      const newInputValues = [...value];
      for (let i = 0; i < pasteContent.length && (index + i) < 6; i++) {
        newInputValues[index + i] = pasteContent[i];
      }
      onChange(newInputValues);

      const nextFieldIndex = Math.min(index + pasteContent.length, 6) - 1;
      inputRefs.current[nextFieldIndex]?.focus();
    }
  };

  return (
    <div className="text-center">
      <div className="flex flex-row gap-x-3 justify-center mt-6">
        {value.map((val, index) => (
          <input
            key={index}
            type="text"
            className="rounded-lg bg-white border-2 h-24 w-12 text-4xl text-center border-gray-400 focus:border-main-color focus:outline-none focus:ring-0"
            value={val}
            onChange={(e) => handleInputChange(index, e)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            onPaste={(e) => handlePaste(index, e)}
            ref={(el) => (inputRefs.current[index] = el)}
            maxLength={1}
          />
        ))}
      </div>
      <div>
        {error && 
          <div className="text-red-500 text-sm mt-4 flex items-center center-items gap-x-2">
            <InformationCircleIcon className="h-6 w-6 mr-1" />
            {"De ingevoerde code is onjuist."}
          </div>
        }
      </div>
    </div>
  );
};

export default TwoFactorAuthInputs;
