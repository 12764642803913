import { useEffect, useState } from "react";
import { BaseModal, SmallButtonYellow } from 'component-library'

import { useTranslation } from "react-i18next";
// import { PasswordCheck } from "@regex/PasswordCheck";
import { useCustomNavigate } from "@components/navigate/useCustomNavigate";
import { useSearchParams } from "react-router-dom";
import { confirmEmailChange, confirmRegistration, loginMagic } from "@api/API";
import { useNotification } from "@context/Notification/NotificationContext";
import { useUser } from "@context/UserContext";

type ResetEmailModalProps = {
  openModal: boolean;
  toggleModal: () => void;
  showCloseButton?: boolean;
  darkMode?: boolean;
}

export const ResetEmailModalConstructor = ({ openModal, toggleModal, darkMode = true }: ResetEmailModalProps) => {
  const { t } = useTranslation()
  const { notify } = useNotification();
  const customNavigate = useCustomNavigate();
  const [searchParams] = useSearchParams();
  // const confirmation_code = searchParams.get('confirmation_code') || "" 
  const activation_code = searchParams.get('activation_code') || ""
  const new_account = searchParams.get('new_email') || ""
  const [validCode, setValidCode] = useState(true)
  const [subtitle, setSubtitle] = useState('')
  const { fetchUserDetails } = useUser() as any;
  let count = 0

  useEffect(() => {
    if(count === 0){
      count++
      getSubtitle()
    }
  }, [])

  //  useEffect(() => {
  //   getSubtitle(); 
  // }, [activation_code, new_account, validCode]); 

  const checkConfirmationCode = async () => {
    let res = await confirmEmailChange(activation_code)
    if(res.status === 200){
      notify(t('codeValid'), 'success')
      setValidCode(false)
    } else {
      notify(t('invalidCode'), 'error')
    }
  }

  const checkActivationCode = async () => {
    let res = await confirmRegistration(activation_code)
    if(res.status === 200){
      notify(t('codeValid'), 'success')
      handleMagicLogin(res.data.data.magicLink)
    } else {
      notify(t('invalidCode'), 'error')
    }
  }

  const handleMagicLogin = async (magicLink: string) => {
    let res = await loginMagic(magicLink)
    if(res.status === 200){
      //delay 1000ms
      if (fetchUserDetails) fetchUserDetails()
      setTimeout(() => {
        customNavigate('/lidmaatschap/activeren')
      }, 500)
      // customNavigate('/lidmaatschap/activeren')
    } else {
      notify(t('loginFailed'), 'error')
    }
  }

  const getSubtitle = () => {
    if(new_account){
      // console.log('do activate')
      setSubtitle(validCode ? t('confirmSetEmailSubtitle') : t('confirmSetEmailSubtitleError'))
      checkActivationCode()
    }
    else {
      // console.log('do confirm')
      setSubtitle(validCode ? t('confirmEmailChangeSubtitle') : t('confirmEmailChangeError'))
      checkConfirmationCode()
    }
    // if(activation_code != ""){
    //   // console.log('do activate')
    //   setSubtitle(validCode ? t('confirmSetEmailSubtitle') : t('confirmSetEmailSubtitleError'))
    //   checkActivationCode()
    // }
    // if(confirmation_code != ""){
    //   // console.log('do confirm')
    //   setSubtitle(validCode ? t('confirmEmailChangeSubtitle') : t('confirmEmailChangeError'))
    //   checkConfirmationCode()
    // }
  }

  return(
    <BaseModal
      open={openModal}
      toggleOpen={toggleModal}
      darkMode={darkMode}
      modalTitle={t('confirmEmailChange')}
      modalSubtitle={subtitle}
    >
      <div className='flex flex-col gap-y-4 mt-2 mb-8 flex-1'> 
      </div>
      <div className='w-full px-8 mt-8 '>
        <SmallButtonYellow
          text={"Terug naar inloggen"}
          onClick={() => customNavigate('/inloggen')}
          disabled={false}
          darkMode={darkMode}
        />
      </div> 
    </BaseModal>
  )
}