import { useNavigate } from "react-router-dom";

// Custom hook names should start with "use"
export const useCustomNavigate = () => {
  const navigate = useNavigate();
  // const BASE_ROUTE = process.env.REACT_APP_BASE_ROUTE;
  const BASE_ROUTE = import.meta.env.VITE_BASE_ROUTE
  // This function inside the hook can now use `navigate`
  const customNavigate = (dir: string) => {
    // Check to see if dir starts with a '/' if it does, remove it
    if (dir.charAt(0) === '/') {
      dir = dir.slice(1);
    }
    navigate(`${BASE_ROUTE}${dir}`);
  };

  // Return the customNavigate function so it can be used by components
  return customNavigate;
};
