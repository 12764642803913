import axios from './HttpService'; 

const APP_URI = import.meta.env.VITE_APP_URI
const PORTAL_URI = import.meta.env.VITE_PORTAL_URI
const OAUTH_URI = import.meta.env.VITE_OAUTH_URI

//"http://oauth2.dev-docker.com"

export const login = async (login: string, password: string, turnstile: string) => {
  return await axios({
    url: `${OAUTH_URI}/api/login/`,
    method: "POST",
    data: {
      email: login,
      password: password,
      remember_me: 1,
      "cf-turnstile-response": turnstile
    },
    withCredentials: true
  })
    .then((response) => { 
      if (response.data.access_token) {
        localStorage.setItem("oauth_token", response.data.access_token);
        return response;
      } else {
        return response;
      }
    })
    .catch((error) => {
      return error.response;
    });
};

export const logout = async () => {
  return await axios({
    url: `${OAUTH_URI}/api/logout/`,
    method: "GET",
    withCredentials: true
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const twoFactorCode = async (code: string) => {
  return await axios({
    url: `${OAUTH_URI}/api/2fa-response/`,
    method: "POST",
    data: {
      code: code,
    },
    withCredentials: true
  })
    .then((response) => {
      if (response.data.access_token) {
        return response;
      } else {
        return response;
      }
    })
    .catch((error) => {
      return error.response;
    });
};

export const requestPasswordReset = async (email: string) => {
  return await axios({
    url: `${APP_URI}/api/profile/password/reset/`,
    method: "POST",
    data: {
      email: email,
    },
  })
    .then((response) => {
      return response; 
    })
    .catch((error) => {
      return error.response;
    });
};

export const verifyCode = async (code: string) => {
  return await axios({
    url: `${APP_URI}/api/activate/${code}/verify`,
    withCredentials: true,
    method: "GET"
  })
    .then((response) => {
      return response; 
    })
    .catch((error) => {
      return error.response;
    });
};

export const createNewAccount = async (data: any) => { 
  return await axios({
    url: `${APP_URI}/api/register`,
    method: "POST",
    data: data,
  })
    .then((response) => {
      return response; 
    })
    .catch((error) => {
      return error.response;
    });
}; 

export const getSchoolByEmail = async (schoolEmail: string) => {
  return await axios({
    url: `${APP_URI}/api/register/emaildomain/${schoolEmail}`,
    method: "GET",
  })
    .then((response) => {
      return response; 
    })
    .catch((error) => {
      return error.response;
    });
}; 

// export const getUserDetail = async () => {
//   return await axios({
//     url: `${PORTAL_URI}/ext_profile/user_detail/`,
//     method: "GET",
//     withCredentials: true,
//   })
//     .then((response) => {
//       return response; 
//     })
//     .catch((error) => {
//       return error.response;
//     });
// }; 

export const activateSubscription = async (code: string, data: any) => { 
  return await axios({
    url: `${APP_URI}/api/register/${code}/activate`,
    method: "POST",
    data: data,
    withCredentials: true
  })
    .then((response) => {
      return response; 
    })
    .catch((error) => {
      return error.response;
    });
};  

export const setNewPassword = async (email: string, activation_key: string, password: string) => { 
  return await axios({
    url: `${APP_URI}/api/profile/password/reset/set`,
    method: "POST",
    data: { email: email, activation_key: activation_key, password: password },
  })
    .then((response) => {
      return response; 
    })
    .catch((error) => {
      return error.response;
    });
}; 


export const registerUser = async (code: string, data: any, type: string) => { 
  return await axios({
    url: `${APP_URI}/api/register/${code}/selfservice/${type}/register`,
    method: "POST",
    data: data,
  })
    .then((response) => {
      return response; 
    })
    .catch((error) => {
      return error.response;
    });
};

export const updateUser = async (data: any) => {
  return await axios({
    url: `${PORTAL_URI}/ext_profile/user_detail/`,
    method: "PUT",
    data: {
      profile: data
    },
    withCredentials: true
  })
    .then((response) => {
      return response; 
    })
    .catch((error) => {
      return error.response;
    });
};

export const get2FaQr = async () => {
  return await axios({
    url: `${OAUTH_URI}/api/2fa-enroll`,
    method: "GET",
    withCredentials: true
  })
    .then((response) => {
      return response; 
    })
    .catch((error) => {
      return error.response;
    });
};

export const submit2FaQr = async (code: string) => {
  return await axios({
    url: `${OAUTH_URI}/api/2fa-enroll`,
    method: "POST",
    data: {
      code: code,
    },
    withCredentials: true
  })
    .then((response) => {
      return response; 
    })
    .catch((error) => {
      return error.response;
    });
};

///api/cm/payment/methods
export const getPaymentMethods = async () => {
  return await axios({
    url: `${APP_URI}/api/cm/payment/methods`,
    method: "GET",
    withCredentials: true
  })
    .then((response) => {
      return response; 
    })
    .catch((error) => {
      return error.response;
    });
};

export const getPaymentMethod = async (id: string) => {
  // let testId = 'KNABNL2H'
  return await axios({
    url: `${APP_URI}/api/cm/payment/${id}`,
    method: "GET",
    withCredentials: true
  })
    .then((response) => {
      return response; 
    })
    .catch((error) => {
      return error.response;
    });
};

export const checkoutIndividual = async (id: any, autoRenew: boolean) => {
  let data = {
      "productOrder": {
          "products": [{"id": id, "quantity": 1}],
          "notes": {"auto_renew": autoRenew == true}
      }
  }
  return await axios({
    url: `${APP_URI}/api/productorders/checkout-individual`,
    method: "POST",
    data: data,
    withCredentials: true
  })
    .then((response) => {
      return response; 
    })
    .catch((error) => {
      return error.response;
    });
};

export const getProducts = async () => {
  return await axios({
    url: `${APP_URI}/api/products?participant=INDIVIDUAL`,
    method: "GET",
    withCredentials: true
  })
    .then((response) => {
      return response; 
    })
    .catch((error) => {
      return error.response;
    });
};

//GET http://app.dev-docker.com/api/profile
export const getProfile = async () => {
  return await axios({
    url: `${APP_URI}/api/profile`,
    method: "GET",
    withCredentials: true
  })
    .then((response) => {
      return response; 
    })
    .catch((error) => {
      return error.response;
    });
};

export const updateProfile = async (data: any) => {
  return await axios({
    url: `${APP_URI}/api/profile`,
    method: "PUT",
    withCredentials: true,
    data: data
  })
    .then((response) => {
      return response; 
    })
    .catch((error) => {
      return error.response;
    });
};

//remove profile
export const deleteProfile = async () => {
  return await axios({
    url: `${APP_URI}/api/profile`,
    method: "DELETE",
    withCredentials: true
  })
    .then((response) => {
      return response; 
    })
    .catch((error) => {
      return error.response;
    });
};

export const cancelSubscription = async () => {
  return await axios({
    url: `${APP_URI}/api/profile/subscriptions`,
    method: "DELETE",
    withCredentials: true
  })
    .then((response) => {
      return response; 
    })
    .catch((error) => {
      return error.response;
    });
};

//http://app.dev-docker.com/api/profile/subscriptions
export const getSubscriptions = async () => {
  return await axios({
    url: `${APP_URI}/api/profile/subscriptions`,
    method: "GET",
    withCredentials: true
  })
    .then((response) => {
      return response; 
    })
    .catch((error) => {
      return error.response;
    });
};

//http://oauth2.dev-docker.com/api/change-password/
export const changePassword = async (password: string) => {
  return await axios({
    url: `${OAUTH_URI}/api/change-password/`,
    method: "POST",
    data: {
      password: password
    },
    withCredentials: true
  })
    .then((response) => {
      return response; 
    })
    .catch((error) => {
      return error.response;
    });
};


///api/profile/email/change
export const changeEmail = async (email: string) => {
  return await axios({
    url: `${APP_URI}/api/profile/email/change`,
    method: "POST",
    data: {
      newEmail: email
    },
    withCredentials: true
  })
    .then((response) => {
      return response; 
    })
    .catch((error) => {
      return error.response;
    });
};

export const confirmEmailChange = async (activationKey: string) => {
  return await axios({
    url: `${APP_URI}/api/profile/email/change/confirm`,
    method: "POST",
    data: {
      activationKey: activationKey
    },
    withCredentials: true
  })
    .then((response) => {
      return response; 
    })
    .catch((error) => {
      return error.response;
    });
};

export const checkCompromisedPassword = async (password: string) => {
  return await axios({
    url: `${APP_URI}/api/profile/password/check`,
    method: "POST",
    data: {
      password: password
    },
    withCredentials: true
  })
    .then((response) => {
      return response; 
    })
    .catch((error) => {
      return error.response;
    });
}

//https://app.acceptance.cjp.nl/api/register/confirm
export const confirmRegistration = async (code: string) => {
  return await axios({
    url: `${APP_URI}/api/register/confirm`,
    method: "POST",
    data: {
      activationKey: code
    },
    withCredentials: true
  })
    .then((response) => {
      return response; 
    })
    .catch((error) => {
      return error.response;
    });
}
//http://oauth2.dev-docker.com/api/login-magic'
export const loginMagic = async (magic_link: string) => {
  return await axios({
    url: `${OAUTH_URI}/api/login-magic`,
    method: "POST",
    data: {
      magic_link: magic_link
    },
    withCredentials: true
  })
    .then((response) => {
      localStorage.setItem("oauth_token", response.data.access_token);
      return response; 
    })
    .catch((error) => {
      return error.response;
    });
}
