import { useEffect, useState } from "react";
// import Logo from '@assets/Logo.png'
// import { useNavigate } from 'react-router-dom';

import CardImage from '@assets/CardImage.png'

import { BaseModal, SmallButtonYellow, Spinner, } from 'component-library'
import { useTranslation } from "react-i18next";
import { useUser } from "@context/UserContext";
import { getSubscriptions } from "@components/api/API";
// import { get } from "http";
import { useCustomNavigate } from "@components/navigate/useCustomNavigate";
import { ClipboardDocumentIcon } from "@heroicons/react/16/solid";


type SuccessModalProps = {
  openModal: boolean;
  toggleModal: () => void;
  showCloseButton?: boolean;
  darkMode?: boolean;
}

export const SuccessModalConstructor = ({ openModal, toggleModal, showCloseButton = false, darkMode = false }: SuccessModalProps) => {
  // const navigate = useNavigate()
  const { t } = useTranslation()
  const { isAuth, isLoading, user } = useUser() as any;
  const [subscription, setSubscription] = useState({} as any)
  const [loading, setLoading] = useState(true)
  const customNavigate = useCustomNavigate();

  useEffect(()=>{
    if(!isLoading){
      if(isAuth){
        getUserSubscription()
        setLoading(false)
      } else {
        customNavigate('/inloggen')
      }
    }
  }, [isAuth, isLoading])

  const copyToClipboard = async (text: string) => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(text);
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        textArea.style.position = 'fixed'; // Avoid scrolling to bottom
        textArea.style.left = '-9999px'; // Hide the textarea off-screen
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        
        const successful = document.execCommand('copy');
        document.body.removeChild(textArea);
        
        if (!successful) {
          throw new Error('Failed to copy text');
        }
      }
      return true;
    } catch (error) {
      console.error('Failed to copy text to clipboard:', error);
      return false;
    }
  };

  const getUrl = () => {
    //get URL based on the current environment from the .env files
    return import.meta.env.VITE_BASE_CJP

  }
  

  const getUserSubscription = async () => {
    const response = await getSubscriptions()
    if(response.status === 200){
      let data = response.data
      if(data.data?.length > 0){
        // setActiveSub(true)
        setSubscription({...data.data[0]})
      }
      else {
        customNavigate('lidmaatschap/kopen')
      }
    }
  }

  //parse date
  const parseDate = (date: string) => {
    let newDate = new Date(date)
    return newDate.toLocaleDateString()
  } 

  return(
    <BaseModal
      open={openModal}
      toggleOpen={toggleModal}
      darkMode={darkMode}
    > 
      <div className={`font-bold ${darkMode ? "text-white" : "text-black"} text-center text-4xl font-formula tracking-wider pt-8`}>
        {t('successHeader')}
      </div>
      {!loading ?
        <div className="flex flex-1 gap-x-6">
          <div className="rounded-lg border-2 border-white overflow-hidden h-fit w-fit">
            <img src={CardImage} alt="gratisImg" className="w-28 aspect-[3/5]" />
          </div>
          <div className={`${darkMode ? "text-white" : "text-black"} `}>
            {/* <div className="font-bold text-lg mt-2">
              {user?.firstName || ""} {user?.lastName || ""}
            </div> */}
            {/* <div>
              {user?.email || ""}
            </div> */}
            <div className={`${darkMode ? "text-white" : "text-black"} `}>
              {t('successAccountEmail')}: <br/><span className="ml-auto w-fit font-bold">{user?.email || ""} </span>
            </div> 
            <div className={`${darkMode ? "text-white" : "text-black"} `}>
              {t('successCode')}: <br/><span className="ml-auto w-fit font-bold">{subscription?.activationCode || ""}</span>
            </div> 
            <div className={`${darkMode ? "text-white" : "text-black"} `}>
              {t('successCjpNum')}: <br/>
            </div>
            <div className="flex font-bold flex-row hover:border-main-color border-white animate group w-fit cursor-pointer border-b" onClick={()=>copyToClipboard(subscription.code)}>
              {subscription.code} <span><ClipboardDocumentIcon className="w-5 h-5 ml-2 animate text-gray-300 group-hover:text-main-color" /></span>
            </div>
            <div className={`${darkMode ? "text-white" : "text-black"} `}>
              {t('successDate')}<br/> <span className="ml-auto w-fit font-bold">{parseDate(subscription?.endDate)}</span>
            </div>
          </div>
        </div>
        :
        <div className="w-fit m-auto">
          <Spinner />
        </div>
      }
      <div className="flex flex-col gap-y-4 mt- ">
        <a href={getUrl()} >
          <SmallButtonYellow
            text="Check alle kortingen!"
            onClick={() => {}}
            // disabled={loginDetails.email.length == 0 || EmailCheck(loginDetails.email)}
          />
        </a>
      </div> 
      <div className="flex flex-col gap-y-4 mt-4 ">
        <SmallButtonYellow
          text="Mijn digitale pas"
          onClick={() => customNavigate('/account')}
          inverted
          // disabled={loginDetails.email.length == 0 || EmailCheck(loginDetails.email)}
        />
      </div> 
    </BaseModal>
  )
}