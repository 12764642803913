import { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';   
import { LoginModalConstructor } from '@components/modal/LoginComponentConstructor';
import { CreateAccountModalConstructor } from '@components/modal/CreateAccountModal';
import { EmailPassModalConstructor } from '@components/modal/EmailPassModal';
import { SuccessModalConstructor } from '@components/modal/SuccessModalConstructor';
import { BuySubscriptionModalConstructor } from '@components/modal/BuySubscriptionModal';

import "/node_modules/flag-icons/css/flag-icons.min.css";
import { SetupTwoFactorModalConstructor } from '@components/modal/SetupTwoFactor';
import { SelectOriginModalConstructor } from '@components/modal/SelectOriginModal';
import { useNotification } from '@context/Notification/NotificationContext';
import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '@components/navigate/useCustomNavigate';
import { ResetEmailModalConstructor } from '@components/mijn/ResetEmailModal';

// import colorful_bg from '@assets/colorful_bg.png';
import { Header } from '@components/header-footer/Header';
import { Footer } from '@components/header-footer/Footer';

function AuthModal() {
  const { t } = useTranslation();
  const customNavigate = useCustomNavigate();
  const [openModal, setOpenModal] = useState(true);
  const toggleModal = () => setOpenModal(true);
  const { notify } = useNotification();
  const [searchParams] = useSearchParams();
  const { modal } = useParams();
  const origin = searchParams.get('origin') as string;
  const product = searchParams.get('product') as string;

  let errorMsgIndex = 0;

  useEffect(() => { 
    const error = searchParams.get('error');
    if (error && errorMsgIndex === 0) {
      errorMsgIndex++;
      notify(t('error-' + error, { portal: origin }), 'error');
      customNavigate('/inloggen');
    }
  }, [searchParams, origin, notify, customNavigate, t]); 

  const darkMode = false;

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 768); 
    };

    checkScreenSize();

    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const renderModal = () => {
    switch (modal) {
      case 'aanmelden':
        return <LoginModalConstructor openModal={openModal} toggleModal={toggleModal} darkMode={darkMode}/>;
      case 'inloggen':
      case 'login':
        return <EmailPassModalConstructor openModal={openModal} toggleModal={toggleModal} origin={origin} darkMode={darkMode} product={product}/>;
      case 'account-aanmaken':
        return <CreateAccountModalConstructor openModal={openModal} toggleModal={toggleModal} darkMode={darkMode}/>;
      // case 'success':
      //   return <SuccessModalConstructor openModal={openModal} toggleModal={toggleModal} darkMode={darkMode}/>;
      // case 'upgrade':
      //   return <BuySubscriptionModalConstructor openModal={openModal} toggleModal={toggleModal} darkMode={darkMode}/>;
      case 'setup-2fa':
        return <SetupTwoFactorModalConstructor openModal={openModal} toggleModal={toggleModal} darkMode={darkMode}/>;
      case 'origin':
        return <SelectOriginModalConstructor openModal={openModal} toggleModal={toggleModal} darkMode={darkMode}/>;
      case 'email-bevestigen':
      case 'confirm-email':
          return <ResetEmailModalConstructor openModal={openModal} toggleModal={toggleModal} darkMode={darkMode}/>;  
      default:
        return <LoginModalConstructor openModal={openModal} toggleModal={toggleModal} darkMode={darkMode}/>;
    }
  };

  return (
    <div className={`bg-slate-100 flex flex-col ${isMobile ? 'min-h-[130vh]' : 'min-h-screen'} ${darkMode ? "dark" : ""}`}>
      <header className="fixed top-0 left-0 right-0 z-[9999]">
        <Header />
      </header>

      <div className="flex-grow flex flex-col items-center justify-center">
        {openModal && (
          <div className={`w-screen place-content-center flex-grow ${openModal ? '' : 'pointer-events-none'} ${isMobile ? 'mb-16' : ''}`}>
            {renderModal()}
          </div>
        )}
      </div>

      <footer className={`w-full ${isMobile ? ' mt-4' : 'fixed bottom-0 left-0 right-0'} z-50`}>
        <Footer />
      </footer>
    </div>
  );
}

export default AuthModal;
