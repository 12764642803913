import { CustomDatePicker } from "@components/CustomDatePicker";
import { InputField, Slider, SmallButtonYellow } from "component-library";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type AccountSettingsProps = {
  darkMode?: boolean;
  loginDetails: any; 
  setLoginDetails: any; 
  handleUpdateAccount: any;
  handleUpdateOptIn: any;  
  getUserProfile: any;
  userProfile: any;
  loadingObj: any;
  optIns: any;
  setOptIns: any;
  handleLogout: () => void;
}

export const AccountSettings = ({ 
  darkMode, 
  loadingObj, 
  loginDetails, 
  setLoginDetails, 
  handleUpdateAccount, 
  handleUpdateOptIn,
  userProfile, 
  optIns, 
  setOptIns, 
  handleLogout
}: AccountSettingsProps) => {
  const { t } = useTranslation()
  const [errorObj, setErrorObj] = useState({
    date: false
  }) 

  useEffect(() => {
    checkDate()
  }, [loginDetails.dateBirth])


  const checkUserAge = () => {
    /** Uses loginDetails.dateBirth to calculate the user's age accurately based on today's date */
    const [day, month, year] = loginDetails.dateBirth.split('-').map(Number);
    const selectedDate = new Date(year, month - 1, day); // month is zero-indexed
    const today = new Date();
  
    let age = today.getFullYear() - selectedDate.getFullYear();
  
    // Adjust age if the user's birthday has not occurred yet this year
    const hasHadBirthdayThisYear =
      today.getMonth() > selectedDate.getMonth() ||
      (today.getMonth() === selectedDate.getMonth() && today.getDate() >= selectedDate.getDate());
  
    if (!hasHadBirthdayThisYear) {
      age -= 1;
    }
    return age;
  };

  const checkDate = () => {
    const [day, month, year] = loginDetails.dateBirth.split('-').map(Number);
    const selectedDate = new Date(year, month - 1, day);

    if(
      (selectedDate > new Date()) ||
      (selectedDate.getFullYear() < 1925) ||
      (checkUserAge() < 10)
    ) {
      setErrorObj({
        ...errorObj,
        date: true
      })
    }
    else {
      setErrorObj({
        ...errorObj,
        date: false
      })
    }
  }

  const updateLoginDetails = (value: any, state: string) => {
    setLoginDetails({
      ...loginDetails,
      [state]: value
    })
  } 

  const updateDate = (value: any, state: string) => { 
    setLoginDetails({
      ...loginDetails,
      ['dateBirth']: value
    })
  } 

  const updateOptIn = (value: any, state: string) => {
    setOptIns({
      ...optIns,
      [state]: value
    })
  }

  const updateAddress = (value: any, state: string) => {
    setLoginDetails({
      ...loginDetails,
      address: {
        ...loginDetails.address,
        [state]: value
      }
    })
  } 

  const checkDetailDifference = () => {
    const profile = JSON.stringify(userProfile)
    const details = JSON.stringify(loginDetails)
    if(errorObj.date){
      return true
    }
    return profile === details
  }

  const compareOptIn = () => {
    const obj1 = JSON.stringify(userProfile.optInPreferences)
    const obj2 = JSON.stringify(optIns)
    return obj1 === obj2 
  } 

  const handleUpdate = () => {
    let body = loginDetails
    handleUpdateAccount(body)
  } 

  return(
    <div className="flex-1 flex flex-col mt-4">
      <div className={`font-bold ${darkMode ? "text-white" : "text-black"} text-left text-4xl font-formula tracking-wider pt-8`}>
        MIJN PROFIEL
      </div>
      <hr className="my-4"/>
      <div className={`font-bold ${darkMode ? "text-white" : "text-black"} text-left text-3xl font-formula tracking-wider pb-4`}>
        {/* ACCOUNT GEGEVENS: */}
        MIJN GEGEVENS:
      </div>
      <div className="flex-1">
        <InputField
          placeholder={t("email")}
          value={loginDetails.email || ""}
          setState={() => {}}
          type="text"
          error={false}
          errorMsg={t("emailError")}
          handleUpdate={updateLoginDetails}
          itemKey={'email'}
          darkMode={darkMode}
          disabled={true}
        />
        <InputField
          placeholder={t("firstName")}
          value={loginDetails.firstName || ""}
          setState={() => {}}
          type="text"
          error={false}
          handleUpdate={updateLoginDetails}
          itemKey={'firstName'}
          darkMode={darkMode}
          disabled={false}
        /> 
        <InputField
          placeholder={t("lastName")}
          value={loginDetails.lastName || ""}
          setState={() => {}}
          type="text"
          error={false}
          handleUpdate={updateLoginDetails}
          itemKey={'lastName'}
          darkMode={darkMode}
          disabled={false}
        /> 
        <CustomDatePicker date={loginDetails.dateBirth || ""} updateDate={updateDate} errorObj={errorObj} />
        <InputField
          placeholder={t("postal")}
          value={loginDetails.address?.zip || ''}
          setState={() => {}}
          type="text"
          error={false}
          handleUpdate={updateAddress}
          itemKey={'zip'}
          darkMode={darkMode}
          disabled={false}
        /> 
        <InputField
          placeholder={t("phone")}
          value={loginDetails.phone || ""}
          setState={() => {}}
          type="text"
          error={false}
          handleUpdate={updateLoginDetails}
          itemKey={'phone'}
          darkMode={darkMode}
          disabled={false}
        />
        <div className="pt-8">
          <SmallButtonYellow
            text={"Opslaan"}
            onClick={() => handleUpdate()}
            disabled={checkDetailDifference()}
            darkMode={darkMode}
            loading={loadingObj.update}
          />
        </div>
        <hr className="my-8" />
        <div className="">
          <div className={`font-bold ${darkMode ? "text-white" : "text-black"} text-left text-3xl font-formula tracking-wider pb-4`}>
            VOORKEUREN VOOR BERICHTEN:
          </div>
          <div className="pb-6">
            Kies hoe je als eerste de beste deals en tips wilt ontvangen.
          </div>
          <div className="gap-y-4 flex flex-col">
            <Slider
              value={optIns.emailMarketing}
              onClick={() => updateOptIn(!optIns.emailMarketing, 'emailMarketing')}
              darkMode={darkMode}
              text={t("emailMarketing")}
            />
            <Slider
              value={optIns.pushNotifications}
              onClick={() => updateOptIn(!optIns.pushNotifications, 'pushNotifications')}
              darkMode={darkMode}
              text={t("pushNotifications")}
            />
          </div>
          <div className="pt-8">
            <SmallButtonYellow
              text={"Opslaan"}
              onClick={() => handleUpdateOptIn()}
              disabled={compareOptIn()}
              darkMode={darkMode}
              loading={loadingObj.optIn}
            />
          </div>
        </div> 
      </div> 
      {/* <hr className="mt-8"/>
      <div className="pt-8">
        <SmallButtonYellow
          text={"Uitloggen"}
          onClick={() => handleLogout()}
          disabled={false}
          darkMode={darkMode}
        />
      </div> */}
    </div>  
  )
}