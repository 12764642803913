import React, { useEffect, useRef, useCallback } from 'react';

/** Type for the MonthPicker component props */
type MonthPickerProps = {
  selectedMonth: string;
  onSelectMonth: (month: string) => void;
};

export const MonthPicker: React.FC<MonthPickerProps> = ({ selectedMonth, onSelectMonth }) => {
  const months = [
    'januari', 'februari', 'maart', 'april', 'mei', 'juni',
    'juli', 'augustus', 'september', 'oktober', 'november', 'december'
  ];

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const index = months.indexOf(selectedMonth);
      container.scrollTo(0, index * 40);
    }
  }, [selectedMonth, months]);

  const handleScroll = useCallback(() => {
    const container = containerRef.current;
    if (container) {
      const scrollPosition = container.scrollTop;
      const monthHeight = 40; // Height of each month item
      const index = Math.round(scrollPosition / monthHeight);
      const newSelectedMonth = months[index] || selectedMonth;
      if (newSelectedMonth !== selectedMonth) {
        onSelectMonth(newSelectedMonth);
      }
    }
  }, [months, selectedMonth, onSelectMonth]);

  return (
    <div
      ref={containerRef}
      className="flex-1 overflow-y-auto h-[200px] select-none"
      onScroll={handleScroll}
    >
      {Array(2).fill(null).map((_, index) => (
        <div key={`empty-top-${index}`} className="h-[40px] flex items-center justify-center text-gray-400">
          {' '}
        </div>
      ))}
      {months.map((month) => (
        <div
          key={month}
          className={`h-[40px] border-b-2 border-gray-100 cursor-pointer flex items-center justify-center cursor-pointer ${selectedMonth === month ? 'font-bold' : 'text-gray-400'}`}
          onClick={() => onSelectMonth(month)}
        >
          {month}
        </div>
      ))}
      {Array(2).fill(null).map((_, index) => (
        <div key={`empty-bottom-${index}`} className="h-[40px] flex items-center justify-center text-gray-400">
          {' '}
        </div>
      ))}
    </div>
  );
};
