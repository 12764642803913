import { useEffect, useState } from "react";
// import { useSearchParams } from 'react-router-dom';

import { BaseModal, InputField, SmallButtonYellow } from 'component-library';

import { EmailCheck } from '@regex/EmailCheck';
import { useTranslation } from "react-i18next";
import { getSchoolByEmail, registerUser } from "@api/API";
import { useCustomNavigate } from "@components/navigate/useCustomNavigate";
import { useNotification } from "@context/Notification/NotificationContext";
import { InformationCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";

type RequestMBOModalProps = {
  openModal: boolean;
  toggleModal: () => void;
  showCloseButton?: boolean;
  darkMode?: boolean;
};

export const RequestMBOModalConstructor = ({
  openModal,
  toggleModal,
  darkMode = false,
}: RequestMBOModalProps) => {
  const { t } = useTranslation();
  const customNavigate = useCustomNavigate();
  const { notify } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [loginDetails, setLoginDetails] = useState({
    email: '',
    password: '',
    confirmEmail: '',
    dob: '',
    subscribe: false,
    code: '',
    firstName: '',
    lastName: '',
    postal: '',
    userType: 'student',
    schoolYear: 1,
  });
  const [userSchool, setUserSchool] = useState('');
  const [schoolUuid, setSchoolUuid] = useState('');
  const [requestSuccess, setRequestSuccess] = useState(false);
   // const [searchParams] = useSearchParams();
  // const showParam = searchParams.get('show'); 

  // useEffect(()=>{
  //   if(showParam == 'true'){
  //     // setShowContent(true)
  //     setInit(true)
  //   }
  // }, [init])
  useEffect(() => {
    if (loginDetails.email.length > 0 && EmailCheck(loginDetails.email)) {
      getSchool();
    }
  }, [loginDetails.email]);

  const updateLoginDetails = (value: any, state: string) => {
    // console.log('updatelogindetails', value, state)
    setLoginDetails({
      ...loginDetails,
      [state]: value,
    });
  };
  
  // useEffect(() => {
  //   if (loginDetails.email.length == 0 || EmailCheck(loginDetails.email)) {
  //     setIsError(false)
  //   } else {
  //     setIsError(true)
  //   }
  // }, [loginDetails.email])

  const getSchool = async () => {
    let email = extractDomainFromEmail(loginDetails.email);

    const response = await getSchoolByEmail(email);
    if (response.status === 200) {
            // console.log('success', response.data.data.schoolName)
      setUserSchool(response.data.data.schoolName);
      setSchoolUuid(response.data.data.schoolUuid);
    } else {
      setUserSchool('');
      setSchoolUuid('');
      console.log('error', response);
    }
  };

  const extractDomainFromEmail = (email: string): string => {
    const atIndex = email.indexOf('@');
    if (atIndex > 0) {
      return email.substring(atIndex + 1);
    } else {
      return '';
    }
  };

  const checkDisabled = () => {
    if (loginDetails.userType === 'student') {
      if (
        !isLoading &&
        userSchool.length > 1 &&
        loginDetails.email.length > 0 &&
        EmailCheck(loginDetails.email) &&
        loginDetails.schoolYear > 0 &&
        loginDetails.firstName.length > 0 &&  
        loginDetails.lastName.length > 0      
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if (
        !isLoading &&
        userSchool.length > 1 &&
        loginDetails.email.length > 0 &&
        EmailCheck(loginDetails.email) &&
        loginDetails.firstName.length > 0 &&  
        loginDetails.lastName.length > 0      
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  const handleRequestCode = async () => {
    const data = {
      email: loginDetails.email,
      schoolYear: loginDetails.schoolYear,
      firstName: loginDetails.firstName,
      lastName: loginDetails.lastName,
    };
    try {
      setIsLoading(true);
      const response = await registerUser(schoolUuid, data, loginDetails.userType);
      if (response.data.success) {
        setRequestSuccess(true);
        notify(t('requestCodeSuccess'), "success");
        customNavigate('/lidmaatschap/activeren');
      } else {
        notify(t('notifyError'), "error");
        setIsLoading(false);
      }
      // console.log('response', response)
    } catch (error) {
      console.log('error', error);
    }
  };

  const parseClassYear = (inputValue: string) => {
    const year = parseInt(inputValue.slice(-1), 10);
    let data = { ...loginDetails };

    if (!isNaN(year) && year >= 1 && year <= 6) {
      data.schoolYear = year;
    } else {
      data.schoolYear = 1;
    }

    setLoginDetails(data);
  };

  // const updateSchoolYear = (value: string) => {
  //   let year = 

  // }

  return (
    <BaseModal
      open={openModal}
      toggleOpen={toggleModal}
      modalTitle={t('requestMbo')}
      modalSubtitle={!requestSuccess ? t('activateCjpSubtitle') : ""}
      darkMode={darkMode}
    >
      {!requestSuccess ? (
        <div className='flex flex-col gap-y-4 mt-2 mb-8 flex-1'>
          <div className={`${darkMode ? "text-white" : "text-black"} flex gap-x-8`}>
            {t("iAmA")}
            <div className="flex items-center gap-x-2 cursor-pointer ml-auto w-fit" onClick={() => updateLoginDetails('student', 'userType')}>
              <div className={`w-6 h-6 overflow-hidden ${darkMode ? "bg-gray-700" : "bg-main-color"} ${loginDetails.userType === "student" ? "border-[5px]" : "border-[12px]"} animate rounded-full relative`}>
              </div>
              <span className={`${loginDetails.userType === 'student' ? "underline decoration-2 decoration-main-yellow" : ""}`}>{t('student')}</span>
            </div>
            <div className="flex items-center gap-x-2 cursor-pointer" onClick={() => updateLoginDetails('teacher', 'userType')}>
              <div className={`w-6 h-6 overflow-hidden ${darkMode ? "bg-gray-700" : "bg-main-color"} ${loginDetails.userType === "teacher" ? "border-[5px]" : "border-[12px]"} animate rounded-full relative`}>
              </div>
              <span className={`${loginDetails.userType === 'teacher' ? "underline decoration-2 decoration-main-yellow" : ""}`}>{t('teacher')}</span>
            </div>
          </div>
          <div className="relative">
            <div className="absolute top-0 right-0 z-50 cursor-pointer" onClick={() => setShowPopup(!showPopup)}>
              <InformationCircleIcon className="w-5 h-5 text-gray-400 hover:text-main-color animate" />
            </div>
            {showPopup && (
              <div className="shadow-lg rounded-lg p-4 absolute z-50 bg-white">
                <div className="right-1 top-1 absolute">
                  <XMarkIcon className="w-5 h-5 cursor-pointer text-gray-400 hover:text-main-color animate" onClick={() => setShowPopup(!showPopup)} />
                </div>
                Bijvoorbeeld 123@student.albeda.nl of c.jp@student.rocva.nl. Hiermee checken we of je recht hebt op een gratis CJP. Voor meer informatie <a className="underline text-main-color" href="https://www.cjp.nl/gratis-cjp-aanvragen" rel='noreferrer' target="_blank">klik hier</a>.
              </div>
            )}
            <InputField
              placeholder={t('schoolEmail')}
              value={loginDetails.email}
              setState={() => { }}
              type="text"
              error={loginDetails.email.length > 0 && !EmailCheck(loginDetails.email)}
              handleUpdate={updateLoginDetails}
              itemKey={'email'}
              darkMode={darkMode}
              errorMsg={t('emailError')}
            />
          </div>
          {userSchool.length > 1 && (
            <div className={`${userSchool.length > 1 ? "max-h-[10000px]" : "max-h-[0px]"} transition-all duration-300`}>
              {loginDetails.userType === 'student' && (
                <InputField
                  placeholder={t('schoolYear')}
                  value={loginDetails.schoolYear.toString()}
                  setState={() => { }}
                  type="number"
                  error={false}
                  handleUpdate={(e: any) => parseClassYear(e)}
                  itemKey={'code'}
                  darkMode={darkMode}
                  errorMsg=""
                  disabled={false}
                />
              )}
              <div className="flex flex-row mt-4 gap-x-4">
                <div className="flex-1">
                  <InputField
                    placeholder={t('firstName')}
                    value={loginDetails.firstName}
                    setState={() => { }}
                    type="text"
                    error={false}
                    handleUpdate={updateLoginDetails}
                    itemKey={'firstName'}
                    darkMode={darkMode}
                    errorMsg=""
                    disabled={false}
                  />
                </div>
                <div className="flex-1">
                  <InputField
                    placeholder={t('lastName')}
                    value={loginDetails.lastName}
                    setState={() => { }}
                    type="text"
                    error={false}
                    handleUpdate={updateLoginDetails}
                    itemKey={'lastName'}
                    darkMode={darkMode}
                    errorMsg=""
                    disabled={false}
                  />
                </div>
              </div>
            </div>
          )}
          <InputField
            placeholder={t('yourSchoolIs')}
            value={userSchool}
            setState={() => { }}
            type="text"
            error={false}
            handleUpdate={updateLoginDetails}
            itemKey={'code'}
            darkMode={darkMode}
            errorMsg=""
            disabled={true}
          />
        </div>
      ) : (
        <div className='flex flex-col gap-y-4 mt-2 mb-8 flex-1'>
          Actievatiecode is aangevraagd!
          <br />
          <br />
          Check je inbox (en spam folder!) op je persoonlijke emailadres om de code te vinden en activeer je CJP pas.
        </div>
      )}
      <div className='w-full px-8 mt-8'>
        <SmallButtonYellow
          text={t('requestCode')}
          onClick={() => handleRequestCode()}
          disabled={checkDisabled()}
          darkMode={darkMode}
          loading={isLoading}
        />
      </div>
      <div className={`underline cursor-pointer text-center mt-4 ${darkMode ? "text-white" : "text-black"}`} onClick={() => customNavigate('/lidmaatschap')}>
        {t('back')}
      </div>
    </BaseModal>
  );
};
