import { useEffect, useState } from "react";

import { BaseModal, InputField, SmallButtonYellow } from 'component-library'

import { EmailCheck } from '@regex/EmailCheck'
import { requestPasswordReset } from "@api/API";
import { useTranslation } from "react-i18next";
import { useCustomNavigate } from "@components/navigate/useCustomNavigate";
import { useNotification } from "@context/Notification/NotificationContext";

type ResetPassModalProps = {
  openModal: boolean;
  toggleModal: () => void;
  showCloseButton?: boolean;
  darkMode?: boolean;
}

export const ResetPassModalConstructor = ({ openModal, toggleModal, showCloseButton = false, darkMode = true }: ResetPassModalProps) => {
  const { t } = useTranslation()
  const { notify } = useNotification();
  const customNavigate = useCustomNavigate();
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)
  const [loginDetails, setLoginDetails] = useState({
    email: '',
    password: ''
  })
  const [newPassRequested, setNewPassRequested] = useState(false)
  
  const updateLoginDetails = (value: string, state: string) => {
    setLoginDetails({
      ...loginDetails,
      [state]: value
    })
  }

  useEffect(() => {
    if (loginDetails.email.length == 0 || EmailCheck(loginDetails.email)) {
      setIsError(false)
    } else {
      setIsError(true)
    }
  }, [loginDetails.email])

  const handleResetPass = async() => {
    setIsLoading(true)
    const response = await requestPasswordReset(loginDetails.email)
    if(response.status === 200){
      setIsCompleted(true)
      setIsLoading(false)
      setNewPassRequested(true)

    } else {
      console.log('error', response)
      notify(t('requestPasswordError'), "error")
      setIsLoading(false)
      setNewPassRequested(true)

    }
  }

  return(
    <BaseModal
      open={openModal}
      toggleOpen={toggleModal}
      darkMode={darkMode}
      modalTitle={t(newPassRequested ? 'passwordRequested' : 'resetPassword' )}
      modalSubtitle={t(newPassRequested ? 'passwordRequestedSubtitle' : 'resetPasswordSubtitle').replace('{{email}}', loginDetails.email)}
    >
      {!newPassRequested ?
        <div className="flex-1 flex flex-col">
          <div className='flex flex-col gap-y-4 mt-2 mb-8 flex-1'>
            <InputField
              placeholder={t('email')}
              value={loginDetails.email}
              setState={() => {}}
              type="text"
              error={isError}
              handleUpdate={updateLoginDetails}
              itemKey={'email'}
              darkMode={darkMode}
              errorMsg={t('emailError')}
            />
            {/* <a className="text-left cursor-pointer underline pt-2" target='_blank' rel='noreferrer' href='https://support.cjp.nl/portal/nl/kb' >
              {t("needSupport")}
            </a> */}
          </div>
          <div className='w-full px-8 mt-8 '>
            <SmallButtonYellow
              text={t('requestNewPassword')}
              onClick={() => handleResetPass()}
              disabled={loginDetails.email.length === 0 || isError || isCompleted}
              darkMode={darkMode}
              loading={isLoading}
            />
          </div> 
          <div className='w-full pt-4 px-8'>
            <div className={`${darkMode ? "text-white" : "text-black"} text-center cursor-pointer underline`} onClick={()=>customNavigate('/inloggen')}>
              {t('back')}
            </div>
          </div>
        </div> 
        :
        <div className="flex flex-col flex-1">
          <div className="flex-1">

          </div>
          <div className='w-full pt-4 px-8'>
            <div className={`${darkMode ? "text-white" : "text-black"} text-center cursor-pointer underline`} onClick={()=>customNavigate('/inloggen')}>
              Terug naar inloggen
            </div>
          </div>
        </div>
      }
    </BaseModal>
  )
}