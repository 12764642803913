import React, { useState, useEffect, useRef, useCallback } from 'react';

/** Type for the DayPicker component props */
type DayPickerProps = {
  selectedDay: number;
  onSelectDay: (day: number) => void;
};

export const DayPicker: React.FC<DayPickerProps> = ({ selectedDay, onSelectDay }) => {
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const index = days.indexOf(selectedDay);
      container.scrollTo(0, index * 40);
    }
  }, [selectedDay, days]);

  const handleScroll = useCallback(() => {
    const container = containerRef.current;
    if (container) {
      const scrollPosition = container.scrollTop;
      const dayHeight = 40; // Height of each day item
      const index = Math.round(scrollPosition / dayHeight);
      const newSelectedDay = days[index] || selectedDay;
      if (newSelectedDay !== selectedDay) {
        onSelectDay(newSelectedDay);
      }
    }
  }, [days, selectedDay, onSelectDay]);

  return (
    <div
      ref={containerRef}
      className="flex-1 overflow-y-auto h-[200px] select-none"
      onScroll={handleScroll}
    >
      {Array(2).fill(null).map((_, index) => (
        <div key={`empty-top-${index}`} className="h-[40px] flex items-center justify-center text-gray-400">
          {' '}
        </div>
      ))}
      {days.map((day) => (
        <div
          key={day}
          className={`h-[40px] border-b-2 border-gray-100 cursor-pointer flex items-center justify-center cursor-pointer ${selectedDay === day ? 'font-bold' : 'text-gray-400'}`}
          onClick={() => onSelectDay(day)}
        >
          {day}
        </div>
      ))}
      {Array(2).fill(null).map((_, index) => (
        <div key={`empty-bottom-${index}`} className="h-[40px] flex items-center justify-center text-gray-400">
          {' '}
        </div>
      ))}
    </div>
  );
};
