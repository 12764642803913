
export const parseDate = (dateStr: string) => {
  const [day, month, year] = dateStr.split('-').map(Number);
  const monthNames = [
    'januari', 'februari', 'maart', 'april', 'mei', 'juni',
    'juli', 'augustus', 'september', 'oktober', 'november', 'december'
  ];
  return {
    day,
    month: monthNames[month - 1],
    year
  };
}; 

export const formatDate = (dateObj: { day: number, month: string, year: number }) => {
  const { day, month, year } = dateObj;
  const monthNames = [
    'januari', 'februari', 'maart', 'april', 'mei', 'juni',
    'juli', 'augustus', 'september', 'oktober', 'november', 'december'
  ];
  const monthIndex = monthNames.indexOf(month) + 1;
  const dayStr = day.toString().padStart(2, '0');
  const monthStr = monthIndex.toString().padStart(2, '0');
  return `${dayStr}-${monthStr}-${year}`;
  // return `${year}-${monthStr}-${dayStr}`;
};
