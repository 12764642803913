import React, { useState, useEffect, useRef, useCallback } from 'react';

/** Type for the YearPicker component props */
type YearPickerProps = {
  selectedYear: number;
  onSelectYear: (year: number) => void;
};

export const YearPicker: React.FC<YearPickerProps> = ({ selectedYear, onSelectYear }) => {
  const years = Array.from({ length: 101 }, (_, i) => i + 1925);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const index = years.indexOf(selectedYear);
      container.scrollTo(0, index * 40);
    }
  }, [selectedYear, years]);

  const handleScroll = useCallback(() => {
    const container = containerRef.current;
    if (container) {
      const scrollPosition = container.scrollTop;
      const yearHeight = 40; // Height of each year item
      const index = Math.round(scrollPosition / yearHeight);
      const newSelectedYear = years[index] || selectedYear;
      if (newSelectedYear !== selectedYear) {
        onSelectYear(newSelectedYear);
      }
    }
  }, [years, selectedYear, onSelectYear]);

  return (
    <div
      ref={containerRef}
      className="flex-1 overflow-y-auto h-[200px] select-none"
      onScroll={handleScroll}
    >
      {Array(2).fill(null).map((_, index) => (
        <div key={`empty-top-${index}`} className="h-[40px] flex items-center justify-center text-gray-400">
          {' '}
        </div>
      ))}
      {years.map((year) => (
        <div
          key={year}
          className={`h-[40px] border-b-2 border-gray-100 cursor-pointer flex items-center justify-center cursor-pointer ${selectedYear === year ? 'font-bold' : 'text-gray-400'}`}
          onClick={() => onSelectYear(year)}
        >
          {year}
        </div>
      ))}
      {Array(2).fill(null).map((_, index) => (
        <div key={`empty-bottom-${index}`} className="h-[40px] flex items-center justify-center text-gray-400">
          {' '}
        </div>
      ))}
    </div>
  );
};
