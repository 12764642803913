import React, { useState, useEffect } from 'react';

// import gratisImg from './../../assets/gratisImg.png'
// import kortingenImg from './../../assets/kortingenImg.png'
// import iedereenImg from './../../assets/iedereenImg.png'
import newPicture from './../../assets/newPicture.png'
import { useTranslation } from 'react-i18next';

const CarouselComponent = ({ darkMode }: any) => {
  const { t } = useTranslation()
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = t('carousel', { returnObjects: true }) as any 

  const imgSelect = (input: string) => {
    switch(input){
      case 'newPicture':
        return newPicture
      default:
        return newPicture
    }
  }
  
  return (
    <div className={`${darkMode ? "text-white" : "text-black"}`}>
      <div className="bg-cover w-full overflow-hidden flex"> 
        {slides.map((slide: any, index: number) => (
          index === currentSlide && (
            <div key={index} className="relative w-full">
              <img 
                src={imgSelect(slide.image)} 
                alt="carousel" 
                className="transition-all duration-300 bg-cover w-3/6 mx-auto rounded-lg object-cover object-top" 
              />
              {/* <div className={"tracking-wide text-white font-formula font-bold text-7xl absolute grid place-content-center uppercase inset-0 z-10 w-full h-full " + slide.classes}>
                {slide.title}
              </div> */}
            </div>
          )
        ))}
      </div>
      <div
        className="text-xl text-center px-8 pt-8"
        dangerouslySetInnerHTML={{ __html: slides[currentSlide].text }}
      >
      </div>
      {/* <div className="flex flex-row w-fit m-auto gap-x-3 mt-5">
        {slides.map((slide: any, index: number) => (
          <div key={index}>
            <div
              onClick={() => setCurrentSlide(index)}
              className={`animate w-4 h-4 rounded-full cursor-pointer ${currentSlide === index ? `${darkMode ? "bg-white" : "bg-black"}` : 'bg-gray-400 cursor-pointer'}`}
            />
          </div>
        ))} 
      </div> */}
    </div>
  );
};

export default CarouselComponent;
