import { useEffect, useState } from 'react' 
import { AccountModalConstructor } from '@components/modal/AccountModalConstructor'
import { useUser } from '@context/UserContext';
import { useCustomNavigate } from '@components/navigate/useCustomNavigate';
import { useNotification } from '@context/Notification/NotificationContext';
import { useTranslation } from 'react-i18next';

function Account({ page }: any) {
  const [openModal, setOpenModal] = useState(true)
  const toggleModal = () => setOpenModal(true)
  const { isAuth, isLoading, user, setUser } = useUser() as any;
  const customNavigate = useCustomNavigate();
  const { notify } = useNotification();
  const { t } = useTranslation();

  useEffect(()=>{
    if(!isLoading){
      if(!isAuth){
        customNavigate('/inloggen')
        notify(t('loginRequired'), "error")
      }
    }
  }, [isLoading, isAuth])

  return (
    <div className='bg-slate-100 min-h-screen'> 
      {/* <div className='absolute inset-0 bg-cover bg-center z-0' style={{backgroundImage: 'url(https://cdn.britannica.com/51/194651-050-747F0C18/Interior-National-Gallery-of-Art-Washington-DC.jpg)'}}></div>  */}
      <div className='absolute inset-0 bg-white bg-center z-0'></div> 
      <div className='absolute inset-0 z-0'></div>  
      {openModal && 
        <div className={`w-screen h-screen absolute inset-0 grid place-content-center z-50 ${openModal ? '' : 'pointer-events-none'}`} >
          <AccountModalConstructor 
            openModal={openModal}
            toggleModal={toggleModal}
            showCloseButton={true}
            darkMode={false}
          />
        </div>
      }
    </div>
  )
}

export default Account