
import { Routes, Route, Navigate } from "react-router-dom"
import AuthModal from './components/auth/AuthModal'  
import Account from "./pages/Account"
import SubscriptionModal from "@components/auth/SubscriptionModal"
import PasswordModal from "@components/auth/PasswordModal" 

function App() {
  
  return (
    <div className='font-roboto'> 
      <Routes>
        <Route path="/:modal" element={ <AuthModal /> } />

        <Route path="/lidmaatschap/" element={ <SubscriptionModal /> } />
        <Route path="/lidmaatschap/:modal" element={ <SubscriptionModal /> } />

        <Route path="/wachtwoord/:modal" element={ <PasswordModal /> } />
        <Route path="/wachtwoord" element={ <PasswordModal /> } />

        <Route path="/account" element={ <Account /> } />

        {/* LEGACY LINKS */}
        <Route path="/r/:modal" element={ <AuthModal /> } />

        <Route path="/r/lidmaatschap/" element={ <SubscriptionModal /> } />
        <Route path="/r/lidmaatschap/:modal" element={ <SubscriptionModal /> } />

        <Route path="/r/wachtwoord/:modal" element={ <PasswordModal /> } />
        <Route path="/r/wachtwoord" element={ <PasswordModal /> } />
        <Route path="/r/account" element={ <Account /> } />

        {/* <Route path="account" element={ <Account /> } />
        <Route path="activeer" element={ <Activate /> } /> */}
        {/* <Route path="login" element={ <EmailPassModalConstructor openModal={true} toggleModal={():void=> {}} product=""/> } /> */}
        <Route path="*" element={<Navigate to="/inloggen" replace />} />
        
      </Routes>
    </div>
  )
}

export default App
